import config from "../config/config";
import verifyToken from "./JwtVerifier";
const axios = require('axios');


axios.interceptors.response.use(
    (response) => {
        return response;
    },
    function (error) {
        const originalRequest = error.config;
        let refreshToken = localStorage.getItem("refresh_token");
        if (refreshToken && error.response && error.response.status === 401 && !originalRequest._retry) {
            //Process all 401 responses
            originalRequest._retry = true;
            const url = `https://${config.cognito.DOMAIN}/oauth2/token?grant_type=refresh_token&client_id=${config.cognito.APP_CLIENT_ID}&refresh_token=${refreshToken}`;
            return axios.create()
                //attempt to get a new token using refresh token
                .post(url, '', {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    }
                })
                .then((res) => {
                    if (res.status === 200) {
                        // check token is ok only on 200 responses
                        const newToken = res.data.id_token;
                        return verifyToken(newToken)
                    }
                    throw Error(`Unexpected return code: ${res.status}`)
                }, (reason) => {
                    // Previously we would force a logout here. However this catch appears to
                    // trigger on any 4xx response of the retry call too giving a bad user experience
                    // console.error(`error during token refresh, forcing logout: ${reason}`);
                    // window.location.pathname = `/logout`;
                    throw Error(`error during token refresh: ${reason}`)
                })
                .then((newToken) => {
                    //store new token
                    localStorage.setItem("token", newToken);
                    return axios(originalRequest);
                })
                .catch(function (error) {
                    console.error(`error during token refresh ${error}`);
                    throw Error(error);
                });
        }
        // rethrow any non 401s
        return Promise.reject(error);
    }
);
