import React, {Component} from 'react';
import config from "../config/config";

class Logout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loggedOut: false
        }
    }

    async componentDidMount() {
        this.setState({authentication: null});
        sessionStorage.removeItem('authentication');
        localStorage.removeItem('token')
        localStorage.removeItem('refresh_token')
        this.setState({loggedOut: true})
    }

    render() {
        if (this.state.loggedOut) {
            const callbackUrl = config.redirects.signOut;
            window.location.replace(`https://${config.cognito.DOMAIN}/logout?client_id=${config.cognito.APP_CLIENT_ID}&logout_uri=${callbackUrl}`)
            return null;
        } else {
            return (
                <div className={"container"}>Logging out...</div>
            );
        }
    }
}

export default Logout;
