import {Button, Card, Form, OverlayTrigger, Tooltip} from "react-bootstrap";
import {DashCircle, Files, PlusCircle, Trash} from "react-bootstrap-icons";
import React from "react";

export default class NotePane extends React.Component {

    renderTooltip = (text, props) => (
        <Tooltip id="button-tooltip" {...props}>
            {text}
        </Tooltip>
    );

    performActionOnCanvasObjects(actionName, action) {
        const object = this.props.canvas.getActiveObject();
        if (!object) {
            console.error(`no object selected to ${actionName}`)
            return
        }

        if (object.type === 'ovNote') {
            console.log(`performing ${actionName} on single item`);
            action(object);
        } else if (object.type === 'activeSelection') {
            console.log(`found selection to perform ${actionName} on`);
            object.getObjects().forEach(subObject => {
                console.log(`performing ${actionName} on sub object`);
                action(subObject)
                subObject.dirty = true
            })
        }
        object.dirty = true

        this.props.canvas.renderAll()
    }

    deleteObject = () => {
        this.performActionOnCanvasObjects('delete', object => {
            this.props.canvas.remove(object)
        })
    }

    processFontSizeChange(change) {
        this.performActionOnCanvasObjects(`changing text size by ${change}`, object => {
            const currentSize = object.fontSize
            const newSize = currentSize + change
            if (newSize < 1 || newSize > 100) {
                console.log('min / max size reached')
            } else {
                object.fontSize = newSize
            }
        })
    }

    render() {
        return <Card className={"mt-1"}>

            <Card.Header>
                Note properties
            </Card.Header>
            <Card.Body>
                <Form>
                    <Form.Group controlId="actions">

                        <Form.Label>Actions</Form.Label><br/>
                        <OverlayTrigger
                            placement="left"
                            delay={{show: 250, hide: 400}}
                            overlay={this.renderTooltip('Delete note')}
                        >
                            <Button onClick={() => this.deleteObject()}
                                    variant="secondary"><Trash/></Button>

                        </OverlayTrigger>
                        <OverlayTrigger
                            placement="right"
                            delay={{show: 250, hide: 400}}
                            overlay={this.renderTooltip('Clone note')}
                        >
                            <Button className={"ml-1"} onClick={() => console.log('Clone not implemented yet')}
                                    variant="secondary"><Files/></Button>

                        </OverlayTrigger>
                    </Form.Group>
                    <Form.Group controlId="text">

                        <Form.Label>Text</Form.Label><br/>

                        <OverlayTrigger
                            placement="left"
                            delay={{show: 250, hide: 400}}
                            overlay={this.renderTooltip('Decrease font size')}
                        >
                            <Button onClick={() => this.processFontSizeChange(-1)}
                                    variant="secondary"><DashCircle/></Button>

                        </OverlayTrigger>
                        <OverlayTrigger
                            placement="right"
                            delay={{show: 250, hide: 400}}
                            overlay={this.renderTooltip('Increase font size')}
                        >
                            <Button onClick={() => this.processFontSizeChange(1)}
                                    className={"ml-1"}
                                    variant="secondary"><PlusCircle/></Button>

                        </OverlayTrigger>
                    </Form.Group>
                </Form>

            </Card.Body>
        </Card>;
    }
}
