import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";

class HowTo extends Component {

        render() {

            const headerStyle = {
                backgroundColor: "#D8FFF7",
                backgroundImage: "linear-gradient(to bottom right,#eae6ff, #fff)",
            };

            return (

                <div>
                    <Helmet>
                        <title>Ovalview | HowTo</title>
                    </Helmet>
                    <div className="jumbotron" style={headerStyle}>
                        <h1 className="display-4 text-center">Ovalview - How To</h1>
                        <p className="lead text-center">A quick start guide to Ovalview and improved visibility of work
                        </p>
                        <br/>
                        <p className="lead" align="center">
                            <button className="btn btn-primary btn-lg" onClick={this.props.history.goBack}>I know already...</button>
                        </p>

                        <br/><br/><br/>
                        <div className="row justify-content-around">

                            <div className="col" align="center">
                                    <p className="display-4 text-center">Step 1 - Select your background</p>
                                    <p className="display-5 text-center">This is the design which you will use as a basis for tasking</p>
                                    <p className="display-5 text-center">Any diagram that expresses your design, sequence diagram, architecture diagram, a captured sketch one the whiteboard.  Whatever the format, If it captures the feature in a format which can be broken down, then it’s the right diagram.</p>
                            </div>
                            <div className="col" align="center">
                                <img src="siteimages/selectImage.png" className="d-block w-75" alt="Select Background"/>
                            </div>
                        </div>

                        <div className="row justify-content-around">
                            <div className="col" align="center">
                                <img src="siteimages/docId.png" className="d-block w-50" alt="Select Background"/>
                                <br/>
                            </div>
                            <div className="col" align="center">
                                <p/>
                                <p/>
                                <p className="display-4 text-center">Step 2 - Name your Page</p>
                                <p className="display-5 text-center">This is the title.  The name by which you identify your Page.  A descriptive title will make retrieving documents easier for users.</p>
                                <p className="display-5 text-center">Naming your page 'Page 1' will be less fun once your library has grown</p>
                            </div>
                        </div>

                        <div className="row justify-content-around">
                            <div className="col" align="center">
                                <br/>
                                <p/>
                                <p className="display-4 text-center">Step 3 - Task out</p>
                                <p className="display-5 text-center">Identify Tasks by surrounding these with an Oval.  Give your task a brief description.  This is all you will need, the diagram provides the context</p>
                            </div>
                            <div className="col" align="center">
                                <img src="siteimages/taskOut.png" className="d-block w-75" alt="Select Background"/>
                                <br/>
                            </div>
                        </div>

                        <p className="display-4 text-center">Other Stuff</p>

                        <p className="display-4 text-center">Jira, Organisations, Folders & User Collaboration</p>
                        <p className="lead" align="center">
                            <Link to="/help" className="btn btn-lg btn-link">Tell me how</Link>
                        </p>

                        <div className="row justify-content-around">
                            <div className="col" align="center">
                                <img src="siteimages/notes.png" className="d-block h-50" alt="Notes"/>
                                <br/>
                            </div>
                            <div className="col" align="center">
                                <p className="display-5 text-left">Notes....</p>
                            </div>
                            <div className="col" align="center">
                                <p className="display-6 text-left">Need to add notes?  Stick a note to your diagram!</p>
                            </div>
                            <div className="col" align="center">
                            </div>

                        </div>
                        <div className="row justify-content-around">
                            <div className="col" align="center">
                                <img src="siteimages/jira-tickets.png" className="d-block h-50" alt="Jira Tickets"/>
                                <br/>
                            </div>
                            <div className="col" align="center">
                                <p className="display-5 text-left">Ticket Creation....</p>
                            </div>
                            <div className="col" align="center">
                                <p className="display-6 text-left">If you’re signed up for Jira integration, then your tickets will be created when you save the Page.
                                    A single story is created for the Page.
                                    A task is created for each Oval on the page.  The title of which will be the text within the Oval
                                </p>
                            </div>
                            <div className="col" align="center">
                            </div>

                        </div>

                    </div>
                </div>
            );
        }
}

export default HowTo
