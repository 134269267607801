import {
    Accordion,
    Button,
    ButtonGroup,
    Card,
    Col,
    Container,
    Form,
    Modal,
    OverlayTrigger,
    Row,
    ToggleButton,
    Tooltip,
    Spinner
} from "react-bootstrap";
import React, {Component} from 'react';
import {CardImage, ChevronDoubleDown, Circle, Cursor, Sticky, Download, Files } from 'react-bootstrap-icons';
import {fabric} from 'fabric';
import {Redirect} from "react-router-dom";
import {createPage, getPage, listPages, updatePage} from '../services/pageService';
import { jiraStory, jiraTasks, getJiraConfig } from '../services/jiraService';
import moment from 'moment';
import config from "../config/config";
import {transformFabricObjectsToNotes, transformFabricObjectsToTasks} from '../lib/FabricJsHelper'
import {STATES} from '../lib/PageConstants'
import OvalTaskPane from '../components/page/OvalTaskPane'
import NotePane from "../components/page/NotePane";
import ErrorAlert from "../components/ErrorAlert";
import SuccessAlert from "../components/SuccessAlert";
import {Helmet} from "react-helmet";
import {getOrgAssignees } from "../services/orgService";

//The following prevents scaling during a circle resize (even though the strokeUniform property prevents the actual scaling happening)
fabric.Object.prototype.noScaleCache = false;

export default class Page extends Component {
    constructor(props) {
        super(props);
        this.state = {
            canvas: null,
            pageId: null,
            title: null,
            redirectUrl: null,
            creating: false,
            canvasSelectionOvalTaskPresent: false,
            canvasSelectionNotePresent: false,
            created: false,
            imagePresent: false,
            saveInProgress: false,
            saveSuccessMessage: null,
            saveErrorMessage: null,
            createPageError: null,
            clickMode: '1',
            page: null,
            persistentErrorMessage: null,
            jiraConfig: null,
            assignees: null,
            backgroundImageUpdated: false,
        }
    }

    async componentDidMount() {
        const {pageId} = this.props.match.params;

        this.setState({pageId: pageId})

        if (this.props.authentication?.organisation) {
            try {
                const assigneeResponse = await getOrgAssignees(this.props.authentication.organisation.id);
                this.setState({
                    assignees: assigneeResponse.assignees,
                });
            } catch (error) {
                this.setState({
                    persistentErrorMessage: `Problem getting assignees: ${error.toString()}`
                });
            }
        }

        const canvas = new fabric.Canvas('c');
        if (pageId === 'new') {
            try {
                if (this.props.authentication) {
                    const stashedPage = sessionStorage.getItem('stashedPage');
                    if (stashedPage) {
                        //If there is a stashed page then always clear it immediately
                        sessionStorage.removeItem('stashedPage')
                    }
                    //User is logged in - get their pages and redirect to banding if limit is reached
                    const pages = await listPages();
                    if (pages.length >= this.props.authentication.features.pageLimit) {
                        console.warn(`User has ${pages.length} pages, redirecting to banding page`);
                        this.setState({redirectUrl: '/banding'})
                    } else {
                        if (stashedPage) {
                            //attempt to save stashed page
                            try {
                                const newPageId = await createPage(stashedPage, null);
                                console.log(`post response was: ${newPageId}`)
                                //redirect to new page on success
                                this.setState({redirectUrl: `/pages/${newPageId}`})
                                return;
                            } catch (err) {
                                //display error and load stashed page on screen - user can decide what to do next
                                await this.loadStashedPage(stashedPage, canvas);
                                this.setState({
                                    saveErrorMessage: `Error saving your page after logging in: ${err}`,
                                    creating: true,
                                    created: true
                                })
                            }
                        } else {
                            // User is logged in, has no stashed page, and is within their page limit
                            // proceed to create page dialog
                            this.setState({canvas, creating: true});
                        }
                    }
                } else {
                    //User is not logged in, proceed to create page dialog
                    this.setState({canvas, creating: true});
                }
            } catch (error) {
                this.setState({
                    persistentErrorMessage: `Problem getting page count: ${error.toString()}`
                });
            }
        } else {
            if (!this.props.authentication) {
                console.log('attempting to view page when not logged in. Redirecting to home');
                this.setState({redirectUrl: '/'})
                return;
            }
            await this.loadExistingPage(pageId, canvas, this.state.assignees);

            //Get jira config if org is present
            if (this.props.authentication.organisation) {
                try {
                    const jiraConfigResponse = await getJiraConfig(this.props.authentication.organisation.id)

                    if (jiraConfigResponse.config.length > 0) {
                        this.setState ({jiraConfig:  jiraConfigResponse.config[0]})
                    }
                } catch (error) {
                    this.setState({
                        persistentErrorMessage: `Problem getting Jira Org: ${error.toString()}`
                    });
                }

            }
        }

        canvas.on('mouse:up', this.canvasClickHandler)
        canvas.on('selection:cleared', this.canvasSelection)
        canvas.on('selection:updated', this.canvasSelection)
        canvas.on('selection:created', this.canvasSelection)
        this.fileSelector = this.buildFileSelector();

        document.onkeydown = (e) => {
            if (e.code === 'Delete' || e.code === 'Backspace') {
                this.deleteObject();
            }
        }
    }

    loadExistingPage = async (pageId, canvas, assignees) => {
        try {
            const data = await getPage(pageId);

            var img = new Image();

            let self = this;

            img.onload = function () {
                //todo consider scaling image to a max height/width here
                var f_img = new fabric.Image(img);

                canvas.setWidth(f_img.width);
                canvas.setHeight(f_img.height);

                canvas.setBackgroundImage(f_img);

                //loop through each oval and create OvalTask obj & add to canvas
                data.tasks.forEach(task => {
                    const ovalTask = new fabric.OvalTask(
                        task.canvasProperties,
                        {fontSize: task.textSize},
                        task.text,
                        STATES[task.status],
                        assignees?.find(user => user.id === task.assigneeId) || null,
                        task.id,
                        task.jiraKey)
                    canvas.add(ovalTask)
                })

                //now notes
                data.notes.forEach(note => {
                    const ovNote = new fabric.OvNote(note.text, {
                        top: note.top,
                        left: note.left,
                        width: note.width,
                        height: note.height,
                        angle: note.angle,
                        fontSize: note.textSize})
                    canvas.add(ovNote)
                })

                canvas.renderAll();

                self.setState({
                    title: data.title,
                    canvas: canvas,
                    page: data
                })
            };

            img.src = data.backgroundImage;

        } catch (error) {
            this.setState({
                persistentErrorMessage: `Problem getting page: ${error.toString()}`
            });
        }
    }

    loadStashedPage = async (stashedPage, canvas) => {
        try {
            const data = JSON.parse(stashedPage)
            var img = new Image();

            let self = this;

            img.onload = function () {
                //todo consider scaling image to a max height/width here
                var f_img = new fabric.Image(img);

                canvas.setWidth(f_img.width);
                canvas.setHeight(f_img.height);

                canvas.setBackgroundImage(f_img);

                //loop through each oval and create OvalTask obj & add to canvas
                data.tasks.forEach(task => {
                    const ovalTask = new fabric.OvalTask(
                        task.canvasProperties,
                        {fontSize: task.textSize},
                        task.text,
                        STATES[task.status],
                        this.state.assignees?.find(user => user.id === task.assigneeId) || null,
                        task.id,
                        task.jiraKey)
                    canvas.add(ovalTask)
                })

                //now notes
                data.notes.forEach(note => {
                    const ovNote = new fabric.OvNote(note.text, {
                        top: note.top,
                        left: note.left,
                        width: note.width,
                        height: note.height,
                        angle: note.angle,
                        fontSize: note.textSize})
                    canvas.add(ovNote)
                })

                canvas.renderAll();

                self.setState({
                    title: data.title,
                    canvas: canvas,
                    page: data
                })
            };

            img.src = data.backgroundImage;

        } catch (error) {
            this.setState({
                persistentErrorMessage: `Problem getting page: ${error.toString()}`
            });
        }
    }

    processFile = (event) => {
        const canvas = this.state.canvas
        var img = new Image();

        let self = this;

        img.onload = function () {
            //todo consider scaling image to a max height/width here
            var f_img = new fabric.Image(img);

            canvas.setWidth(f_img.width);
            canvas.setHeight(f_img.height);

            canvas.setBackgroundImage(f_img);

            canvas.renderAll();

            self.setState({
                imagePresent: true,
                createPageError: null
            })
        };

        let reader = new FileReader();
        reader.onload = function () {
            img.src = reader.result;
        };
        reader.onerror = function (error) {
            console.error('Error: ', error);
        };

        const fileSize = event.target.files[0].size
        if (fileSize > config.page.max_image_size_bytes) {
            const msg = `file must be < ${config.page.max_image_size_bytes} bytes. Was: ${fileSize}`;
            if (this.state.creating && !this.state.created) {
                this.setState({createPageError: msg})
            } else {
                this.setState({saveErrorMessage: msg})
            }
            console.error(msg)
        } else {
            reader.readAsDataURL(event.target.files[0]);
            this.setState({backgroundImageUpdated: true})
        }
    }

    deleteObject = () => {
        const object = this.state.canvas.getActiveObject();

        if (!object) {
            return;
        }

        if (object.type === 'activeSelection') {
            object.getObjects().forEach(obj => {
                this.state.canvas.remove(obj)
            })
        } else {
            if (!object.isEditing) {
                this.state.canvas.remove(object);
            }
        }
        this.state.canvas.renderAll()
    }

    enableJiraIntegration = async () => {
        this.setState({saveInProgress: true})
        //do save
        const saveSuccess = await this.save(false, false);
        if (!saveSuccess) {
            return;
        }
        console.log(`Completed pre jira integration save`);

        let story;
        try {
            const res = await jiraStory(this.state.pageId, this.props.authentication.organisation.id)
            story = res.key
        } catch (e) {
            this.setState({saveErrorMessage: `Error enabling Jira integration: ${e}`, saveInProgress: false});
            return;
        }
        console.log(`Called story endpoint`);

        //todo probably doesn't need a full sync here
        //refresh page
        try {
        await this.refreshPage();
        } catch (e) {
            this.setState({saveErrorMessage: `Error refreshing page following Jira integration: ${e}`, saveInProgress: false});
            return;
        }
        console.log(`refreshed page`);
        this.setState({saveSuccessMessage: `Jira integration is now enabled. Story: ${story}`, saveInProgress: false})
    }

    performJiraSync = async () => {
        this.setState({saveInProgress: true})

        //do save
        const saveSuccess = await this.save(false, false);
        if (!saveSuccess) {
            return;
        }
        console.log(`Completed pre jira sync save`);

        try {
            await jiraTasks(this.state.pageId, this.props.authentication.organisation.id)
        } catch (e) {
            this.setState({saveErrorMessage: `Error performing jira sync: ${e}`, saveInProgress: false});
            return;
        }
        console.log(`Called tasks endpoint`);

        //refresh page
        try {
            await this.refreshPage();
        } catch (e) {
            this.setState({saveErrorMessage: `Error refreshing page following push to Jira: ${e}`, saveInProgress: false});
            return;
        }
        console.log(`refreshed page`);
        this.setState({saveSuccessMessage: `All tasks pushed to Jira`, saveInProgress: false})
    }

    download = async () => {
        const image = this.state.canvas.toDataURL({
            format: 'png',
            quality: 1
        });

        const a = document.createElement("a");
        a.href = image;
        a.download = "image.png";
        a.click();
    }

    /**
     *  updateInProgressStateVar - update saveInProgress state var. Defaults to true. Should be false if the caller already does thiis
     *  displaySuccessMessage - show success message. true by default
     */
    save = async (updateInProgressStateVar = true, displaySuccessMessage = true) => {
        if (updateInProgressStateVar) {
            this.setState({saveInProgress: true})
        }
        const json = this.state.canvas.toJSON()

        try {
            if (this.state.creating) {
                if (!this.props.authentication) {
                    console.log('not logged in, prompt for log in');
                    this.setState({showSignInModal: true})
                } else {
                    console.log('new page, will POST');
                    const exportedImage = this.state.canvas.toDataURL({
                        format: 'png',
                        quality: 1
                    });
                    const newPageId = await createPage(JSON.stringify({
                        title: this.state.title,
                        backgroundImage: json.backgroundImage.src,
                        tasks: transformFabricObjectsToTasks(json.objects),
                        notes: transformFabricObjectsToNotes((json.objects))
                    }), exportedImage);
                    console.log(`post response was: ${newPageId}`)
                    this.setState({redirectUrl: `/pages/${newPageId}`})
                }
            } else {
                console.log('existing page, will PUT');
                const exportedImage = this.state.canvas.toDataURL({
                    format: 'png',
                    quality: 1
                });
                await updatePage(this.state.pageId, JSON.stringify({
                    title: this.state.title,
                    backgroundImage: this.state.backgroundImageUpdated ? json.backgroundImage.src : null,
                    tasks: transformFabricObjectsToTasks(json.objects),
                    notes: transformFabricObjectsToNotes((json.objects))
                }), exportedImage )

                await this.refreshPage();

                if (displaySuccessMessage) {
                    this.setState({saveSuccessMessage: `Successfully saved page '${this.state.title}'`})
                    this.setState({backgroundImageUpdated: false})
                }
            }
        } catch (e) {
            if (e.response?.data?.error) {
                this.setState({saveErrorMessage: `Error saving page: ${e.response.data.error}`});
            } else {
                //Not a recognised error, just show what we can
                this.setState({saveErrorMessage: `Error saving page:  ${e.toString()}`});
            }
            this.setState({
                saveInProgress: false,
            })
            return false;
        }
        if (updateInProgressStateVar) {
            this.setState({saveInProgress: false})
        }
        return true;
    }

    refreshPage = async() => {
        const page = await getPage(this.state.pageId)
        this.setState({modifiedAt: page.modifiedAt, page});

        //Clear oval tasks & recreate to take populate new Jira tasks
        this.state.canvas.getObjects().filter((obj) => obj.type === "ovalTask").forEach(task => {
            this.state.canvas.remove(task)
        })

        page.tasks.forEach(task => {
            const ovalTask = new fabric.OvalTask(
                task.canvasProperties,
                {fontSize: task.textSize},
                task.text,
                STATES[task.status],
                this.state.assignees?.find(user => user.id === task.assigneeId) || null,
                task.id,
                task.jiraKey)
            this.state.canvas.add(ovalTask)
        });
        this.state.canvas.renderAll();
    }

    create = async () => {
        this.setState({created: true})
    }

    cancelCreate = async () => {
        this.props.history.goBack();
    }

    signInClicked = async () => {
        console.log('stashing page')
        const json = this.state.canvas.toJSON();
        sessionStorage.setItem('stashedPage', JSON.stringify({
            title: this.state.title,
            backgroundImage: json.backgroundImage.src,
            tasks: transformFabricObjectsToTasks(json.objects),
            notes: transformFabricObjectsToNotes(json.objects)
        }));
        this.setState({redirectUrl: '/login'})
    }

    cancelSignIn = async () => {
        this.setState({showSignInModal: false})
    }

    canvasClickHandler = (evt) => {
        if (this.state.canvas.getActiveObject() != null) {
            return
        }
        if (this.state.clickMode === '1') {
            console.log('canvas click while in pointer mode, nothing to do')
        } else if (this.state.clickMode === '2') {
            const taskLimit = this.props.authentication?.organisation?.config?.taskLimit ? this.props.authentication.organisation.config.taskLimit : config.page.default_max_circles
            if (this.state.canvas.getObjects().filter((obj) => obj.type === "ovalTask").length >= taskLimit ) {
                const message = `${taskLimit} oval limit in your Ovalview plan`
                this.setState({saveErrorMessage: message});
                console.warn(message)
                return;
            }

            const ellipseOptions = {
                left: evt.pointer.x,
                top: evt.pointer.y,
            }
            // drawing a circle via a canvas click only needs the pointer x & y coords for left and top
            const ovalTask = new fabric.OvalTask(ellipseOptions, {}, '', STATES.NOT_DONE, null)
            this.state.canvas.add(ovalTask)
            this.state.canvas.renderAll();
        } else if (this.state.clickMode === '3'){
            const noteLimit = this.props.authentication?.organisation?.config?.noteLimit ? this.props.authentication.organisation.config.noteLimit : config.page.default_max_circles
            if (this.state.canvas.getObjects().filter((obj) => obj.type === "ovNote").length >= noteLimit) {
                const message = `${noteLimit} note limit in your Ovalview plan`
                this.setState({saveErrorMessage: message});
                console.warn(message)
                return;
            }
            const note = new fabric.OvNote('',
                {
                    left: evt.pointer.x,
                    top: evt.pointer.y,
                })
            this.state.canvas.add(note)
            this.state.canvas.renderAll();
        } else {
            console.warn(`unknown clickMode: ${this.state.clickMode}`);
        }

    };

    canvasSelection = () => {
        if (this.selectedObjectIsOfType('ovalTask')) {
            this.setState({
                canvasSelectionOvalTaskPresent: true,
                canvasSelectionNotePresent: false
            })
        } else if (this.selectedObjectIsOfType('ovNote')) {
            this.setState({
                canvasSelectionNotePresent: true,
                canvasSelectionOvalTaskPresent: false,
            })
        } else {
            this.setState({
                canvasSelectionOvalTaskPresent: false,
                canvasSelectionNotePresent: false
            })
        }
    }

    selectedObjectIsOfType(type) {
        return this.state.canvas.getActiveObject()
            && (this.state.canvas.getActiveObject().type === type ||
                (this.state.canvas.getActiveObject().type === 'activeSelection'
                    && this.state.canvas.getActiveObject().getObjects().filter(obj => obj.type === type).length === this.state.canvas.getActiveObject().getObjects().length));
    }

    titleChanged = (event) => {
        this.setState({title: event.target.value});
    }

    buildFileSelector = () => {
        const fileSelector = document.createElement('input');
        fileSelector.setAttribute('type', 'file');
        fileSelector.setAttribute('accept', 'image/*');
        fileSelector.onchange = this.processFile;
        return fileSelector;
    }

    handleFileSelect = (e) => {
        e.preventDefault();
        this.fileSelector.click();
    }

    copyLiveLink = (e) => {
        navigator.clipboard.writeText(`${config.liveLink.baseUrl}/${this.state.page.id}.png`);
        this.setState({saveSuccessMessage: `Copied Live Link URL to clipboard`, saveInProgress: false})
    }

    renderTooltip = (text, props) => (
        <Tooltip id="button-tooltip" {...props}>
            {text}
        </Tooltip>
    );

    clearSaveSuccessMessage = () => {
        this.setState({
            saveSuccessMessage: null,
        })
    }

    clearSaveErrorMessage = () => {
        this.setState({
            saveErrorMessage: null,
        })
    }



    render() {

        const divStyle = {
            width: '100%',
            height: '800px',
            backgroundImage: "linear-gradient(to bottom right,#eae6ff, #fff)",
            backgroundSize: 'cover'
        };

        if (this.state.redirectUrl) {
            return <Redirect to={this.state.redirectUrl}/>
        } else {
            return (
                <div style={divStyle}>
                    <Helmet>
                        <title>Ovalview | Page</title>
                    </Helmet>

                    <style type="text/css">
                        {`
    .scrolling   {
    overflow-y: scroll;
    overflow-x: scroll;
    height:1000px;
    }

    `}
                    </style>

                    <Container fluid>
                        <ErrorAlert message={this.state.persistentErrorMessage} delay={0}/>
                        <Row>
                            <Col xs={10} >
                                <h3>{this.state.title}</h3>

                                <Card className="w-100 pad1">
                                    <Card.Body>
                                        <div className={"scrolling"}>
                                            <canvas id="c"/>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                            {this.state.canvas && ((!this.state.creating) || (this.state.creating && this.state.created))  ? (
                                <Col xs={2} className={"px-0"} aria-label={'pagePane'}>
                                    <ErrorAlert message={this.state.saveErrorMessage} onClose={this.clearSaveErrorMessage} delay={3000}/>
                                    <SuccessAlert message={this.state.saveSuccessMessage} onClose={this.clearSaveSuccessMessage}/>

                                    {this.state.saveInProgress && <div><Spinner animation="border" role="status"/></div>}

                                    <OverlayTrigger
                                        placement="top"
                                        delay={{show: 250, hide: 400}}
                                        overlay={this.renderTooltip('Save page')}
                                    >
                                        <Button onClick={() => this.save()} className={'mr-1'}
                                                disabled={this.state.saveInProgress}
                                                variant="secondary">Save</Button>

                                    </OverlayTrigger>

                                    <OverlayTrigger
                                        placement="top"
                                        delay={{show: 250, hide: 400}}
                                        overlay={this.renderTooltip('Download page as PNG')}
                                    >
                                        <Button onClick={() => this.download()} className={'mr-1'}
                                                disabled={this.state.saveInProgress}
                                                variant="secondary"><Download/></Button>


                                    </OverlayTrigger>

                                        <br/>

                                    <ButtonGroup toggle className={"my-2"} aria-label={'clickActions'}>
                                        <OverlayTrigger
                                            placement="bottom"
                                            delay={{show: 250, hide: 400}}
                                            overlay={this.renderTooltip('Pointer')}
                                        >
                                            <ToggleButton
                                                key="1"
                                                type="radio"
                                                variant="secondary"
                                                name="pointer"
                                                value="1"
                                                checked={this.state.clickMode === '1'}
                                                onChange={(e) => {
                                                    this.state.canvas.discardActiveObject().renderAll();
                                                    this.setState({clickMode: '1'})
                                                }}
                                            >
                                                <Cursor/>
                                            </ToggleButton>
                                        </OverlayTrigger>
                                        <OverlayTrigger
                                            placement="bottom"
                                            delay={{show: 250, hide: 400}}
                                            overlay={this.renderTooltip('Oval')}
                                        >
                                            <ToggleButton
                                                key="2"
                                                type="radio"
                                                variant="secondary"
                                                name="oval"
                                                value="2"
                                                checked={this.state.clickMode === '2'}
                                                onChange={(e) => this.setState({clickMode: '2'})}
                                            >
                                                <Circle/>
                                            </ToggleButton>
                                        </OverlayTrigger>
                                        <OverlayTrigger
                                            placement="bottom"
                                            delay={{show: 250, hide: 400}}
                                            overlay={this.renderTooltip('Note')}
                                        >
                                            <ToggleButton
                                                key="3"
                                                type="radio"
                                                variant="secondary"
                                                name="oval"
                                                value="3"
                                                checked={this.state.clickMode === '3'}
                                                onChange={(e) => this.setState({clickMode: '3'})}
                                            >
                                                <Sticky/>
                                            </ToggleButton>
                                        </OverlayTrigger>
                                    </ButtonGroup>

                                    {this.state.jiraConfig && (
                                        <Card className={"mt-1"}>
                                            <Card.Header>
                                                Jira
                                            </Card.Header>
                                            <Card.Body>
                                                {this.state.page.jiraKey ? '' :
                                                    <OverlayTrigger
                                                        placement="top" rootClose
                                                        delay={{show: 250, hide: 0}}
                                                        overlay={this.renderTooltip('Enable Jira integration for page')}>
                                                        <Button onClick={() => this.enableJiraIntegration()} variant="secondary" disabled={this.state.saveInProgress}>Enable</Button>
                                                    </OverlayTrigger>}
                                                {this.state.page.jiraKey ?  <OverlayTrigger
                                                    placement="top" rootClose
                                                    delay={{show: 250, hide: 0}}
                                                    overlay={this.renderTooltip('Push all tasks to Jira')}>
                                                        <Button variant="secondary" onClick={() => this.performJiraSync()}  disabled={this.state.saveInProgress}>Sync</Button>
                                                </OverlayTrigger>: ''}
                                                {this.state.page.jiraKey ? <p>Story {' '}<a href={`${this.state.jiraConfig.host}/browse/${this.state.page.jiraKey}`} target="_blank" rel="noopener noreferrer">
                                                    {this.state.page.jiraKey}
                                                </a>
                                                </p> : ''}
                                                {this.state.canvas.getActiveObject() &&  this.state.canvas.getActiveObject().jiraKey ?
                                                    <span>
                                                        Task {' '}
                                                        <a href={`${this.state.jiraConfig.host}/browse/${this.state.canvas.getActiveObject().jiraKey}`}
                                                           target="_blank" rel="noopener noreferrer">
                                                            {this.state.canvas.getActiveObject().jiraKey}
                                                        </a>
                                                    </span> : ''}

                                            </Card.Body>
                                        </Card>
                                    )}

                                    <Accordion className={"mt-1"}>
                                        <Card>
                                            <Card.Header>
                                                <Accordion.Toggle as={Button} variant={"link"} eventKey="0" >
                                                    <ChevronDoubleDown/> Page Details
                                                </Accordion.Toggle>
                                            </Card.Header>
                                            <Accordion.Collapse eventKey="0">
                                                <Card.Body>
                                                    <Form>
                                                        <Form.Group>
                                                            <Form.Control size="sm" type="text" onChange={this.titleChanged}
                                                                          value={this.state.title ? this.state.title : ''}/>
                                                        </Form.Group>
                                                    </Form>
                                                    {!this.state.creating && this.state.page ?  <div><p><b>Created</b>{' '}
                                                        {moment(this.state.page.createdAt).format('MM/DD/YYYY, HH:mm:ss')}</p>
                                                        <p><b>Modified</b>{' '}
                                                            {moment(this.state.page.modifiedAt).format('MM/DD/YYYY, HH:mm:ss')}</p>
                                                    </div> : ''}
                                                    <OverlayTrigger
                                                        placement="top"
                                                        delay={{show: 250, hide: 400}}
                                                        overlay={this.renderTooltip('Change Background image')}
                                                    >
                                                        <Button onClick={this.handleFileSelect} className={'mt-1'}
                                                                variant="secondary">Background <CardImage/></Button>
                                                    </OverlayTrigger>
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>

                                    </Accordion>

                                    {this.state.page &&
                                        <Accordion className={"mt-1"}>
                                            <Card>
                                                <Card.Header>
                                                    <Accordion.Toggle as={Button} variant={"link"} eventKey="0">
                                                        <ChevronDoubleDown/>Live link
                                                    </Accordion.Toggle>
                                                </Card.Header>
                                                <Accordion.Collapse eventKey="0">
                                                    <Card.Body>
                                                        <p>Share a read only link to this page. Even to users without an
                                                            Ovalview account</p>
                                                        <p><a
                                                            href={`${config.liveLink.baseUrl}/${this.state.page.id}.png`}
                                                            target="_blank" rel="noopener noreferrer">Open live link</a></p>
                                                        <OverlayTrigger
                                                            placement="top"
                                                            delay={{show: 250, hide: 400}}
                                                            overlay={this.renderTooltip('Copy page link URL to clipboard')}
                                                        >
                                                            <Button onClick={this.copyLiveLink}
                                                                    variant="secondary"><Files/></Button>
                                                        </OverlayTrigger>

                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Card>

                                        </Accordion>
                                    }

                                    {this.state.canvasSelectionOvalTaskPresent ? (
                                        <OvalTaskPane canvas={this.state.canvas} assignees={this.state.assignees}/>
                                    ) : ''}

                                    {this.state.canvasSelectionNotePresent ? (
                                        <NotePane canvas={this.state.canvas}/>
                                    ) : ''}

                                </Col>
                            ) :''}
                            {!this.state.canvas && !this.state.creating && !this.state.persistentErrorMessage && <p>loading page...</p>}
                        </Row>
                    </Container>

                    <Modal show={this.state.creating && !this.state.created} onHide={this.cancelCreate} aria-label={'createPageModal'} backdrop="static">
                        <Modal.Header closeButton>
                            <Modal.Title>Create A New Page</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Card className={'my-2'}>
                                <Form className={'m-2'}>
                            <div className="container-fluid align-content-center">
                            <img src="/siteimages/selectImage.png" alt="Select Background"/>
                            </div>
                            <div className="align-content-md-center">
                            <b>Select the background</b>
                            </div>

                                    <Form.Group>
                                        <Form.File id="createPageSelectBackground"
                                                   accept={'image/*'}
                                                   onChange={this.processFile} data-testid={'createPageSelectBackground'}/>
                                    </Form.Group>

                                    <Form.Group>
                                        <b>Name The Page</b>
                                        <Form.Control type="input" placeholder="Enter a name for the Page"
                                                      onChange={this.titleChanged} aria-label={'createPageTitle'}/>
                                    </Form.Group>

                                    {this.state.createPageError ? <p className="text-danger">{this.state.createPageError}</p> : ''}

                                </Form>
                            </Card>
                        </Modal.Body>
                        <Modal.Footer>

                            <Button variant="secondary" onClick={this.cancelCreate}>Cancel</Button>
                            <Button variant="primary" disabled={!this.state.title || !this.state.imagePresent}
                                    onClick={this.create}>
                                Create
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal show={this.state.showSignInModal} onHide={this.cancelSignIn} aria-label={'signInModal'}>
                        <Modal.Header closeButton>
                            <Modal.Title>Sign in required</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>You must first Sign in or Sign up before you can save a diagram</p>
                        </Modal.Body>
                        <Modal.Footer>

                            <Button variant="secondary" onClick={this.cancelSignIn}>Cancel</Button>
                            <Button variant="primary" onClick={this.signInClicked}>
                                Sign in / Sign Up
                            </Button>
                        </Modal.Footer>
                    </Modal>


                </div>
            );
        }
    }
}
