import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";

class Banding extends Component {
    
        render() {

            const redtier = {
                backgroundImage: "url('siteimages/red-band.png')",
                backgroundSize: 'cover'
            };

            const orangetier = {
                backgroundImage: "url('siteimages/orange-band.png')",
                backgroundSize: 'cover'
            };

            const greentier = {
                backgroundImage: "url('siteimages/green-band.png')",
                backgroundSize: 'cover'
            };

            const headerStyle = {
                backgroundColor: "#D8FFF7",
                backgroundImage: "linear-gradient(to bottom right,#eae6ff, #fff)",
            };

                return (

                    <div>
                        <Helmet>
                            <title>Ovalview | Banding</title>
                        </Helmet>
                        <div className="jumbotron" style={headerStyle}>
                            <h1 className="display-4 text-center">Ovalview - Making work visible</h1>
                            <p className="lead text-center">Extend your visibility
                            </p>
                            <br/>
                            <p className="lead" align="center">
                                <Link to="/pages" className="btn btn-primary btn-lg">Back to Pages</Link>
                            </p>


                            <br/><br/><br/>
                            <div className="row justify-content-around">

                                <div className="col" align="center">
                                    <div style={redtier}>
                                        <h1 className="display-4 text-center">Free Teir</h1>
                                        <p className="lead">Work With Multiple Pages</p>
                                        <p className="lead">Private Folder</p>
                                        <p className="lead">Limited Ovals and Notes</p>
                                        <p className="lead invisible">-</p>
                                        <p className="lead invisible">-</p>
                                        <br/>
                                        <p className="lead" align="center">
                                            <Link to="/pages" className="btn btn-primary btn-lg disabled invisible">Sign Up</Link>
                                        </p>
                                        <br/>
                                    </div>
                                </div>
                                <div className="col" align="center">
                                    <div style={orangetier}>
                                        <h1 className="display-4 text-center">Small Org</h1>
                                        <p className="lead">Collaborate on Documents</p>
                                        <p className="lead">Teams of upto 5 users supported</p>
                                        <p className="lead">Shared and Private Folders</p>
                                        <p className="lead">Upto 100 Pages</p>
                                        <p className="lead">Jira Integration</p>
                                        <br/>
                                        <p className="lead" align="center">
                                            <Link to="/pages" className="btn btn-primary btn-lg disabled">Subscribe</Link>
                                        </p>
                                        <br/>
                                    </div>
                                </div>
                                <div className="col" align="center">
                                    <div style={greentier}>
                                        <h1 className="display-4 text-center">Large Org</h1>
                                        <p className="lead">Unlimited Pages</p>
                                        <p className="lead">Unlimited Folders</p>
                                        <p className="lead">Colaborate with others</p>
                                        <p className="lead">Extend Jira Integration</p>
                                        <p className="lead">Large Teams</p>
                                        <br/>
                                        <p className="lead" align="center">
                                            <Link to="/pages" className="btn btn-primary btn-lg disabled">Coming Soon</Link>
                                        </p>
                                        <br/>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                );

    }
}

export default Banding
