import {Button, Table,} from "react-bootstrap";
import React, {Component} from 'react';
import {Redirect} from "react-router-dom";
import {getOrgs, updateOrg} from '../services/orgService';
import moment from "moment";
import {Gear} from "react-bootstrap-icons";
import SubscriptionChange from '../components/admin/SubscriptionChange';
import {Helmet} from "react-helmet";

export default class Admin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            orgs: [],
            redirectUrl: null,
            name: null,
            subscriptionToChange: null,
            subscriptionChangeErrorMessage: null,
            subscriptionChangeValue: null,
        }
    }

    async componentDidMount() {
        if (!this.props.authentication) {
            console.warn('Not logged in, redirecting')
            this.setState({redirectUrl: '/'});
            return;
        }
        const orgs = await getOrgs();
        this.setState({orgs: orgs});

    }

    subscriptionChangeStatusClick = (orgId, orgStatus) => {
        console.log(`changeSubscriptionStatusClick: ${orgId}`);
        this.setState({
            subscriptionToChange: {
                orgId: orgId,
                status: orgStatus
            }
        })
    }

    cancelSubscriptionChange = (event) => {
        this.setState({subscriptionToChange: null})
        this.clearSubscriptionChangeErrorMessage();
    }

    clearSubscriptionChangeErrorMessage = () => {
        this.setState({
            subscriptionChangeErrorMessage: null,
        })
    };

    performSubscriptionChange = async () => {
        console.log('changing paid status');
        console.log(`value: ${this.state.subscriptionToChange.status}`)

        try {
            await updateOrg(this.state.subscriptionToChange.orgId, this.state.subscriptionToChange.status);
        } catch (err) {
            this.setState( {subscriptionChangeErrorMessage: `Error updating subscription status: ` + err.toString()});
            return;
        }
        const orgs = await getOrgs();
        this.setState({orgs: orgs,
            subscriptionToChange: null});
    }

        render() {

            const divStyle = {
                width: '100%',
                height: '800px',
                backgroundImage: "linear-gradient(to bottom right,#eae6ff, #fff)",
                backgroundSize: 'cover'
            };

            if (this.state.redirectUrl) {
                return <Redirect to={this.state.redirectUrl}/>
        } else {
            return (
                this.state.orgs ?
                    <div style={divStyle}>
                        <Helmet>
                            <title>Ovalview | Admin</title>
                        </Helmet>
                <div className={'container'}>
                    <h3>Orgs</h3>
                    <Table striped bordered hover>
                        <thead>
                        <tr>
                            <th>Org</th>
                            <th>Status</th>
                            <th>Updated</th>
                            <th>Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            this.state.orgs.map((org, index) => (
                                <tr id={`org${index}`} key={org.id}>
                                    <td>{org.name}</td>
                                    <td>{org.status}</td>
                                    <td>{moment(org.modifiedAt).fromNow()}</td>
                                    <td>
                                            <Button aria-label={"change subscription status"}
                                                    onClick={() => this.subscriptionChangeStatusClick(org.id, org.status)}
                                                    variant="outline-dark"
                                                    size="sm">
                                                <Gear/>
                                            </Button>

                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                    <div>
                        <SubscriptionChange subscriptionToChange={this.state.subscriptionToChange}
                                    cancelAction={this.cancelSubscriptionChange}
                                    performChangeAction={this.performSubscriptionChange}
                                    errorMessage={this.state.subscriptionChangeErrorMessage}
                                    clearErrorAction={this.clearSubscriptionChangeErrorMessage}/>
                    </div>
                </div>
                    </div>
                :  <div style={divStyle}>
                    <p>loading...</p>
                </div>
            );
        }
    }
}
