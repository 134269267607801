import config from "../config/config";

const axios = require('axios');

export const getJiraConfig = async (orgId) => {
    try {
    const response = await axios.get(`${config.backend_urls.jiraConfig}?orgId=${orgId}`);
    return response.data;
    } catch (error) {
        console.error(`error getting jira config: ${error}`);
        throw error;
    }
}

export const createJiraConfig = async (orgId, projectKey, host, username, password) => {
    try {
        await axios.post(`${config.backend_urls.jiraConfig}`, {orgId, projectKey, host, username, password},
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
    } catch (error) {
        console.error(`error creating jira config: ${error}`);
        throw error;
    }
}

export const jiraStory = async (pageId, orgId) => {
    try {
        const response = await axios.post(`${config.backend_urls.jira}/story`, {pageId, orgId},
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
        return response.data;
    } catch (error) {
        console.error(`error calling jira story endpoint: ${error}`);
        throw error;
    }
}

export const jiraTasks = async (pageId, orgId) => {
    try {
        await axios.post(`${config.backend_urls.jira}/tasks`, {pageId, orgId},
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
    } catch (error) {
        console.error(`error calling jira tasks endpoint: ${error}`);
        throw error;
    }
}

axios.interceptors.request.use(function (config) {
    const token = localStorage.getItem('token');
    config.headers.Authorization = `Bearer ${token}`;
    return config;
}, function (error) {
    return Promise.reject(error);
});
