import React from "react";
import ReactDOM from "react-dom";
import MyApp from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap.css"
import { BrowserRouter } from 'react-router-dom';


// import ReactDOM from "react-dom";
import {GA4React} from "ga-4-react";
const ga4react = new GA4React("G-G81J98PELE");

(async _ => {

    await ga4react.initialize()
        .then(res => console.log("Analytics Success."))
        .catch(err => console.log("Analytics Failure."))
        .finally(() => {
            ReactDOM.render(
                    <BrowserRouter>
                        <App />
                    </BrowserRouter>,
                document.getElementById('root')
            );
        });
})();

class App extends React.Component {
    render() {
        return (
            <div className="container-fluid">
                <MyApp />
            </div>
        );
    }
}

// ReactDOM.render(
//     <BrowserRouter>
//             <App />
//     </BrowserRouter>,
//     document.getElementById('root'),
// );
