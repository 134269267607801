import config from "../config/config";

const axios = require('axios');

export const createOrg = async (name) => {
    try {
        const response = await axios.post(`${config.backend_urls.organisations}`, {name},
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
        return response.headers['location'];
    } catch (error) {
        console.error(`error creating org: ${error}`);
        throw error;
    }
}

export const getOrgs = async () => {
    try {
        const response = await axios.get(`${config.backend_urls.organisations}`,
            {
                headers: {
                    'Content-Type': 'application/json'
                },
                params: {
                    all: 'true'
                }
            });
        return response.data;
    } catch (error) {
        console.error(`error getting org: ${error}`);
        throw error;
    }
}


export const updateOrg = async (orgId, subscriptionStatus) => {
    try {
        await axios.put(`${config.backend_urls.organisations}/${orgId}`, { status: subscriptionStatus}, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    } catch (error) {
        console.error(`error updating organisation ${orgId}: ${error}`);
        throw error;
    }

}


export const getOrgMembers = async (orgId) => {
    try {
        const response = await axios.get(`${config.backend_urls.organisations}/${orgId}/members`,
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
        return response.data;
    } catch (error) {
        console.error(`error getting org members: ${error}`);
        throw error;
    }
}

export const addUserToOrg = async (orgId, email) => {
    try {
        await axios.post(`${config.backend_urls.organisations}/${orgId}/members`, {email},
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
    } catch (error) {
        console.error(`error adding user to org: ${error}`);
        throw error;
    }
}

export const removeUserFromOrg = async (orgId, email) => {
    try {
        await axios.delete(`${config.backend_urls.organisations}/${orgId}/members`, {data: {email}},
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
    } catch (error) {
        console.error(`error removing user from org: ${error}`);
        throw error;
    }
}


export const addAssigneeToOrg = async (orgId, name) => {
    try {
        await axios.post(`${config.backend_urls.organisations}/${orgId}/assignees`, {name},
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
    } catch (error) {
        console.error(`error adding assignee to org: ${error}`);
        throw error;
    }
}

export const getOrgAssignees = async (orgId) => {
    try {
        const response = await axios.get(`${config.backend_urls.organisations}/${orgId}/assignees`,
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
        return response.data;
    } catch (error) {
        console.error(`error getting org assignees: ${error}`);
        throw error;
    }
}
export const removeAssigneeFromOrg = async (orgId, id) => {
    try {
        await axios.delete(`${config.backend_urls.organisations}/${orgId}/assignees`, {data: {id}},
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
    } catch (error) {
        console.error(`error removing assignee from org: ${error}`);
        throw error;
    }
}

axios.interceptors.request.use(function (config) {
    const token = localStorage.getItem('token');
    config.headers.Authorization = `Bearer ${token}`;
    return config;
}, function (error) {
    return Promise.reject(error);
});
