import React, {Component} from 'react';
import {Carousel} from "react-bootstrap";
import {Link} from "react-router-dom";
import {Redirect} from "react-router";
import {Helmet} from "react-helmet";

class Home extends Component {
        render() {

            const headerStyle = {
                backgroundColor: "#D8FFF7",
                backgroundImage: "linear-gradient(to bottom right,#eae6ff, #fff)",
            };

            if (this.props.authentication) {
                return <Redirect to='/Pages'/>
            } else {
                return (

                    <div>
                        <Helmet>
                            <title>Ovalview | Home</title>
                        </Helmet>
                        <div className="jumbotron" style={headerStyle}>
                            <h1 className="display-4 text-center">Ovalview - Making work visible</h1>
                            <p className="lead text-center">Enabling you to visualise team progress in simple colour-coded activity
                                breakdown.
                                There is no longer any reason to wade through endless textual representations of
                                tasks</p>
                            <br/>
                            <p className="lead" align="center">
                                <Link to="/pages/new" className="btn btn-primary btn-lg">Get Started Now</Link>
                            </p>
                            <p className="lead" align="center">
                                Want to watch rather than read? Then checkout
                                <br/>
                                <Link to="/Videos" className="btn btn-primary btn-lg">Video guides</Link>
                            </p>


                            <br/><br/><br/>

                            <div className="row justify-content-around">
                                <div className="col-4" align="center">
                                </div>
                                <div className="col-4" align="center">
                            <Carousel interval="3000">
                                    <Carousel.Item>
                                        <img src="siteimages/selectImage.png" className="d-block w-100" alt="Select Background"/>
                                        <Carousel.Caption>
                                            <h5>Select Your Background Image</h5>
                                        </Carousel.Caption>
                                    </Carousel.Item>
                                    <Carousel.Item >
                                        <img src="siteimages/taskOut.png" className="d-block w-100" alt="Task Out"/>
                                        <Carousel.Caption>
                                            <h5>Task Out</h5>
                                        </Carousel.Caption>
                                    </Carousel.Item>
                                    <Carousel.Item >
                                        <img src="siteimages/doneProgress.png" className="d-block w-100" alt="Done"/>
                                         <Carousel.Caption>
                                             <h5>Done - Visualised Progress</h5>
                                         </Carousel.Caption>
                                    </Carousel.Item>
                            </Carousel>
                                </div>
                                <div className="col-4" align="center">
                                </div>
                            </div>

                        <br/><br/>
                            <h2 className="text-center">Visualise</h2>
                            <p className="lead text-center">Visually represent your work. Ovalview provides the clarity to your team
                                that only a diagram can achieve</p>

                            <div className="row" align="center">
                                <div className="col mb-4 align-content-center"><img
                                    src="siteimages/mainscreen.png"
                                    width="1902" height="1334" className="img-fluid d-block w-75" alt="Main View"/>
                                </div>
                            </div>
                            <div align="center">
                                <Link to="/pages/new" className="align-content-center btn btn-outline-primary btn-lg">Go
                                    Visualise →</Link>
                            </div>


                            <br/><br/><br/>

                            <div className="row justify-content-around">

                                <div className="col-4" align="center">
                                    <h2 className="text-center">Colour-Coded Clarity</h2>
                                    <p/>
                                    <p className="lead">Quickly determine progress.  Task status is quickly identified using the Traffic Light Rating system</p>
                                    <p className="lead"><b style={{color:'red'}}>Not Done</b> → <b style={{color:'orange'}}> In Progress </b>→ <b style={{color:'green'}}> Done</b></p>

                                </div>
                                <div className="col-4" align="center">
                                    <img src="siteimages/colour-coded.png" className="img-fluid d-block w-75" alt="Colour Coded"/>
                                </div>

                            </div>

                            <br/><br/><br/>

                            <div className="row justify-content-around">

                                <div className="col-4" align="center">
                                    <img src="siteimages/cooperate.png" className="img-fluid d-block w-75" alt="Colour Coded"/>
                                </div>
                                <div className="col-4" align="center">
                                    <h2 className="text-center">Cooperation</h2>
                                    <p/>
                                    <p className="lead">Associating team members with tasks highlights necessary integrations and participants ahead of time, resulting in increased understanding and increased velocity
                                    </p>
                                </div>

                            </div>

                            <br/><br/><br/>

                            <div className="row justify-content-around">

                                <div className="col-4" align="center">
                                    <h2 className="text-center">Tool Integration</h2>
                                    <p/>
                                    <p className="lead">Integrated to Jira.  Automatically create Stories and Tasks.  Task progression updates the underlying jira status</p>
                                    <p className="lead"><b style={{color:'red'}}>Backlog</b> → <b style={{color:'orange'}}> In Progress </b>→ <b style={{color:'green'}}> Done</b></p>
                                </div>
                                <div className="col-4" align="center">
                                    <img src="siteimages/jira-integration.png" className="img-fluid d-block w-75" alt="Jira Integration"/>
                                </div>

                            </div>

                            <p className="lead" align="center">
                                <Link to="/pages/new" className="btn btn-primary btn-lg">Get Started Now</Link>
                            </p>

                        </div>

                    </div>
                );
            }
    }
}

export default Home
