import config from "../config/config";

const axios = require('axios');

export const getUserByEmail = async (email) => {
    try {
        const response = await axios.get(`${config.backend_urls.users}`, { params: {
                email}});
        return response.data;
    } catch (error) {
        console.error(`error user by emailAddress ${email}: ${error}`);
        console.info(error.response.data.error);
        return error.response.data;
    }
}

export const getUserInfo = async () => {
    try {
        const response = await axios.get(config.backend_urls.userInfo);
        return response.data;
    } catch (error) {
        console.error(`error retrieving user info: ${error}`);
        throw error;
    }
}


axios.interceptors.request.use(function (config) {
    const token = localStorage.getItem('token');
    config.headers.Authorization = `Bearer ${token}`;
    return config;
}, function (error) {
    return Promise.reject(error);
});
