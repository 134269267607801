import React, {Component} from "react";
import {Container} from "react-bootstrap";
import {Route, Switch} from 'react-router-dom';
import Home from './pages/Home';
import LoginCallback from './components/LoginCallback';
import Pages from './pages/Pages';
import Page from './pages/Page';
import CreateOrganisation from './pages/CreateOrganisation';
import Banding from "./pages/Banding";
import Privacy from "./pages/Privacy";
import Tos from "./pages/Tos";
import HowTo from "./pages/HowTo";
import Videos from "./pages/Videos";
import Help from "./pages/Help";
import Header from "./components/Header";
import Login from "./components/Login";
import Logout from "./components/Logout";
import About from "./pages/About";
import Admin from "./pages/Admin";
import Contact from "./pages/Contact";
import './lib/axiosCommon'
import Organisation from "./pages/Organisation";



class App extends Component {
    constructor(props) {
        super(props);
        const authentication = JSON.parse(sessionStorage.getItem('authentication'))
        this.state = {
            authentication: authentication,
        };
    }

    onSignIn = (idToken, features, organisation) => {
        const authentication = {
            email: idToken.email,
            name: idToken.name,
            sub: idToken.sub,
            features,
            organisation
        }
        this.setState({authentication: authentication});
        sessionStorage.setItem('authentication', JSON.stringify(authentication));
    }
    
    onOrgCreate = (organisation) => {
        const authentication = {
            ...this.state.authentication,
            organisation
        }
        this.setState({authentication: authentication});
        sessionStorage.setItem('authentication', JSON.stringify(authentication));
    }

    render() {
        return (
            <Container fluid>
                <Header
                    authentication={this.state.authentication}/>
                <main>
                    <Switch>
                    <Route exact path="/">
                        <Home authentication={this.state.authentication}/>
                    </Route>
                    <Route exact path="/pages">
                        <Pages authentication={this.state.authentication}/>
                    </Route>
                    <Route exact path="/pages/:pageId" render={(props) => (
                        <Page {...props} authentication={this.state.authentication} key={props.match.params.pageId || 'empty'}/>
                    )}/>
                    <Route exact path="/createOrganisation" render={(props) => (
                        <CreateOrganisation {...props} authentication={this.state.authentication} onOrgCreate={this.onOrgCreate}/>
                    )}/>
                    <Route exact path="/organisations/:orgId" render={(props) => (
                        <Organisation {...props} authentication={this.state.authentication} key={props.match.params.orgId || 'empty'}/>
                    )}/>
                    <Route exact path="/admin">
                        <Admin authentication={this.state.authentication}/>
                    </Route>


                        <Route path="/logincallback">
                        <LoginCallback authentication={this.state.authentication}
                                       onSignIn={this.onSignIn}/>
                    </Route>
                    <Route path="/Banding" component={Banding}/>
                    <Route path="/privacy" component={Privacy}/>
                    <Route path="/tos" component={Tos}/>
                    <Route path="/HowTo" component={HowTo}/>
                    <Route path="/Videos" component={Videos}/>
                    <Route path="/Help" component={Help}/>
                    <Route path="/login" component={Login}/>
                    <Route path="/logout" component={Logout}/>
                    <Route path="/about" component={About}/>
                    <Route path="/contact" component={Contact}/>
                    </Switch>
                </main>
            </Container>
        );
    }
}

export default App;
