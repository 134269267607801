export const USERS = [
    {name: 'User 1', colour: 'pink', initials: 'U1', id: '7b4f9858-36b3-4755-997a-5becd20e21bc'},
    {name: 'User 2', colour: 'Aqua', initials: 'U2', id: '044ff405-b82b-4e25-867b-e9236d965b58'},
    {name: 'User 3', colour: 'Chocolate', initials: 'U3', id: '8c4d1cfa-98f9-4d9d-87f3-1413b55befe6'},
]

export const STATES = {
    NOT_DONE: {colour: 'red', text: 'Not Done', value: 'NOT_DONE'},
    IN_PROGRESS: {colour: 'DarkOrange', text: 'In Progress', value: 'IN_PROGRESS'},
    DONE: {colour: 'green', text: 'Done', value: 'DONE'},
    OPTIONAL: {colour: 'blue', text: 'Optional', value: 'OPTIONAL'},
}
