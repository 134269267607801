const dev = {
    cognito: {
        REGION: "eu-west-2",
        USER_POOL_NAME: "ovalview-dev",
        DOMAIN: "auth.dev.ovalview.io",
        USER_POOL_ID: "eu-west-2_jqUXiJFC2",
        APP_CLIENT_ID: "m7v4ieino7fea14n4vggvasnc"
    },
    backend_urls: {
        pages: "https://yli6tm8f01.execute-api.eu-west-2.amazonaws.com/dev/pages",
        folders: "https://yli6tm8f01.execute-api.eu-west-2.amazonaws.com/dev/folders",
        users: "https://yli6tm8f01.execute-api.eu-west-2.amazonaws.com/dev/users",
        userInfo: "https://yli6tm8f01.execute-api.eu-west-2.amazonaws.com/dev/userInfo",
        organisations: "https://yli6tm8f01.execute-api.eu-west-2.amazonaws.com/dev/organisations",
        jiraConfig: "https://yli6tm8f01.execute-api.eu-west-2.amazonaws.com/dev/jira-config",
        jira: "https://yli6tm8f01.execute-api.eu-west-2.amazonaws.com/dev/jira"
    },
    redirects: {
        signIn: 'https://dev.ovalview.io/logincallback',
        signOut: 'https://dev.ovalview.io'
    },
    page: {
        default_max_circles: 10,
        max_image_size_bytes: 1000000
    },
    liveLink: {
        baseUrl: 'https://ovalview-exports-dev.s3.eu-west-2.amazonaws.com'
    }
};

const prod = {
    cognito: {
        REGION: "eu-west-2",
        USER_POOL_NAME: "ovalview-prod",
        DOMAIN: "auth.ovalview.io",
        USER_POOL_ID: "eu-west-2_RPA10MZNb",
        APP_CLIENT_ID: "9t5a2e5rkf9tsb591hpoafccd"

    },
    backend_urls: {
        pages: "https://f37obf6m04.execute-api.eu-west-2.amazonaws.com/prod/pages",
        folders: "https://f37obf6m04.execute-api.eu-west-2.amazonaws.com/prod/folders",
        users: "https://f37obf6m04.execute-api.eu-west-2.amazonaws.com/prod/users",
        userInfo: "https://f37obf6m04.execute-api.eu-west-2.amazonaws.com/prod/userInfo",
        organisations: "https://f37obf6m04.execute-api.eu-west-2.amazonaws.com/prod/organisations",
        jiraConfig: "https://f37obf6m04.execute-api.eu-west-2.amazonaws.com/prod/jira-config",
        jira: "https://f37obf6m04.execute-api.eu-west-2.amazonaws.com/prod/jira"
    },
    redirects: {
        signIn: 'https://ovalview.io/logincallback',
        signOut: 'https://ovalview.io'
    },
    page: {
        default_max_circles: 10,
        max_image_size_bytes: 1000000
    },
    liveLink: {
        baseUrl: 'https://ovalview-exports.s3.eu-west-2.amazonaws.com'
    }
}

const local = {
    ...dev,
    backend_urls: {
        pages: "http://localhost:4000/local/pages",
        folders: "http://localhost:4000/local/folders",
        users: "http://localhost:4000/local/users",
        userInfo: "http://localhost:4000/local/userInfo",
        organisations: "http://localhost:4000/local/organisations",
        jiraConfig: "http://localhost:4000/local/jira-config",
        jira: "http://localhost:4000/local/jira",
    },
    redirects: {
        signIn: 'http://localhost:3000/logincallback',
        signOut: 'http://localhost:3000'
    },
    liveLink: {
        baseUrl: 'https://ovalview-exports-dev.s3.eu-west-2.amazonaws.com'
    }

}

const local_dev = {
    ...dev,
    redirects: {
        signIn: 'http://localhost:3000/logincallback',
        signOut: 'http://localhost:3000'
    }
};

var config;

switch(process.env.REACT_APP_STAGE) {
    case "production":
        config = prod;
        break;
    case "local": // React app running locally, local backend
        config = local;
        break;
    case "local_dev": // React app running locally, dev backend
        config = local_dev;
        break;
    case "dev":
        config = dev;
        break;
    default:
        config = local_dev;
}

console.log(`Using Ovalview conf: ${process.env.REACT_APP_STAGE ? process.env.REACT_APP_STAGE : `not specified, defaulting to local_dev`}`)

export default config;
