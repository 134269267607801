import React, {Component} from 'react';
import config from "../config/config";

class Login extends Component {
    encryptStringWithSHA256 = async str => {
        const PROTOCOL = 'SHA-256'
        const textEncoder = new TextEncoder();
        const encodedData = textEncoder.encode(str);
        return crypto.subtle.digest(PROTOCOL, encodedData);
    }

    hashToBase64url = arrayBuffer => {
        const items = new Uint8Array(arrayBuffer)
        const stringifiedArrayHash = items.reduce((acc, i) => `${acc}${String.fromCharCode(i)}`, '')
        const decodedHash = btoa(stringifiedArrayHash)

        return decodedHash.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '')
    }

    getRandomString = () => {
        const randomItems = new Uint32Array(28);
        crypto.getRandomValues(randomItems);
        const binaryStringItems = randomItems.map(dec => `0${dec.toString(16).substr(-2)}`)
        return binaryStringItems.reduce((acc, item) => `${acc}${item}`, '');
    }

    async componentDidMount() {
        // Create random "state"
        const state = this.getRandomString();
        sessionStorage.setItem("pkce_state", state);

        // Create PKCE code verifier
        const code_verifier = this.getRandomString();
        sessionStorage.setItem("code_verifier", code_verifier);

        // Create code challenge
        var arrayHash = await this.encryptStringWithSHA256(code_verifier);
        var code_challenge = this.hashToBase64url(arrayHash);
        sessionStorage.setItem("code_challenge", code_challenge)

        // Redirect
        const callbackUrl = config.redirects.signIn;
        window.location.replace(`https://${config.cognito.DOMAIN}/oauth2/authorize?response_type=code&state=${state}&client_id=${config.cognito.APP_CLIENT_ID}&redirect_uri=${callbackUrl}&scope=openid&code_challenge_method=S256&code_challenge=${code_challenge}`)
    }

    render() {
        return (
            <div className={"container"}>redirecting...</div>
        );

    }
}

export default Login;
