import React, {Component} from "react";
import {Alert} from "react-bootstrap";

export default class SuccessAlert extends Component {
    componentDidUpdate(prevProps) {
        if (!prevProps.message && this.props.message) {
            console.log(`successAlert - found message. Will clear after 3000ms`)
            this.timerHandle = window.setTimeout(()=>{
                this.props.onClose()
            },3000)
        }
    }


    componentWillUnmount() {
        if (this.timerHandle) {                 
            clearTimeout(this.timerHandle);      
            this.timerHandle = 0;                
        }
    }

    render() {
        return (
            <Alert aria-label={'successAlert'} key={'successAlert'} variant={'success'} show={!!this.props.message}
                   dismissible={true} onClose={this.props.onClose} >
                {this.props.message}
            </Alert>
        )
    }
}
