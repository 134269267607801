import React, {Component} from 'react';
import {getFoldersByOrg, createFolder} from '../../services/folderService';
import ErrorAlert from "../../components/ErrorAlert";
import {Button, Card, Form, Modal, OverlayTrigger, Table, Tooltip} from "react-bootstrap";
import {Plus} from "react-bootstrap-icons";
import SuccessAlert from "../SuccessAlert";

export default class Folders extends Component {
    constructor(props) {
        super(props);
        this.state = {
            folders: null,
            folderNameToCreate: null,

            showCreateModal: false,

            createFolderError: null,
            persistentErrorMessage: null,
            temporaryErrorMessage: null,
            successMessage: null,
        }
    }

    async componentDidMount() {
        try {
            const foldersResponse = await getFoldersByOrg(this.props.org.id);
            this.setState({
                folders: foldersResponse
            });
        } catch (error) {
            this.setState({
                persistentErrorMessage: `Problem getting folders: ${error.toString()}`
            });
        }
    }

    cancelCreateFolder = async () => {
        this.setState({
            showCreateModal: false,
            folderNameToCreate: null
        });
        this.clearCreateFolderError();
    }

    createClicked = async () => {
        this.setState({showCreateModal: true});
    }

    nameChanged = (event) => {
        this.setState({folderNameToCreate: event.target.value});
    }

    createFolder = async () => {
        try {
            await createFolder(this.props.org.id, this.state.folderNameToCreate);
            await this.refreshFolders();
            this.setState({
                showCreateModal: false,
                folderNameToAdd: null,
                successMessage: `Successfully created ${this.state.folderNameToCreate}`
            })
        } catch (e) {
            if (e.response?.data?.error === 'Invalid value') {
                this.setState({createFolderError: `Invalid folder name`});
            } else if (e.response?.data?.error) {
                this.setState({createFolderError: e.response.data.error});
            } else {
                //Not a recognised error, just show what we can
                this.setState({createFolderError: `Problem creating folder: ${e.toString()}`});
            }
        }
    }

    refreshFolders = async () => {
        try {
            const foldersResponse = await getFoldersByOrg(this.props.org.id);
            this.setState({
                folders: foldersResponse,
            });
        } catch (e) {
            this.setState({temporaryErrorMessage: `Unable to reload folders: ` + e.toString()});
        }
    }


    clearCreateFolderError = (event) => {
        this.setState({createFolderError: null});
    }
    clearTemporaryErrorMessage = () => {
        this.setState({
            temporaryErrorMessage: null,
        })
    };
    clearSuccessMessage = () => {
        this.setState({
            successMessage: null,
        })
    };

    renderTooltip = (text, props) => (
        <Tooltip id="button-tooltip" {...props}>
            {text}
        </Tooltip>
    );

    render() {
        return (
            <div>
                <Card>

                    <Card.Body>
                        {!this.state.folders && !this.state.persistentErrorMessage && <p>loading folders...</p>}
                        <ErrorAlert message={this.state.persistentErrorMessage} delay={0}/>
                        <ErrorAlert message={this.state.temporaryErrorMessage}
                                    onClose={this.clearTemporaryErrorMessage} delay={3000}/>
                        <SuccessAlert message={this.state.successMessage} onClose={this.clearSuccessMessage}/>
                        {this.state.folders &&
                        <div>
                            <h5>Folders</h5>
                            {this.state.folders.length ? (
                                <Table striped bordered hover size="sm" className={"mt-1"}>
                                    <thead>
                                    <tr>
                                        <th>Name</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.state.folders.map((folder, index) => (
                                        <tr id={`folder${index}`} key={folder.id}
                                            aria-label={`row${index}`}>
                                            <td>{folder.name}</td>
                                        </tr>))}
                                    </tbody>
                                </Table>) : <p>This org has no folders</p>}
                            <OverlayTrigger
                                placement="right"
                                delay={{show: 250, hide: 200}}
                                overlay={this.renderTooltip('Add folder to org')}>
                                <Button disabled={this.props.disabled} onClick={this.createClicked} aria-label={"btnCreateFolder"}>
                                    <Plus/>
                                </Button>
                            </OverlayTrigger>
                        </div>
                        }

                    </Card.Body>
                </Card>
                <Modal show={this.state.showCreateModal} onHide={this.cancelCreateFolder} aria-label={'createFolderModal'}
                       backdrop="static">
                    <Modal.Header closeButton>
                        <Modal.Title>Add a folder to org: {this.props.org.name}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <ErrorAlert message={this.state.createFolderError} delay={3000}
                                    onClose={this.clearCreateFolderError}/>
                        <Card className={'my-2'}>
                            <Form className={'m-2'}>
                                <Form.Group>
                                    <b>Name</b>
                                    <Form.Control type="input" placeholder="Folder name"
                                                  onChange={this.nameChanged} aria-label={'createFolderName'}/>
                                </Form.Group>
                            </Form>
                        </Card>
                    </Modal.Body>
                    <Modal.Footer>

                        <Button variant="secondary" onClick={this.cancelCreateFolder}>Cancel</Button>
                        <Button variant="primary" disabled={!this.state.folderNameToCreate}
                                onClick={this.createFolder}>
                            Create
                        </Button>
                    </Modal.Footer>
                </Modal>

            </div>

        );
    }
}
