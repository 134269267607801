import React from "react";
import {Button, Form, Modal} from "react-bootstrap";
import ErrorAlert from "../ErrorAlert";

export default class SubscriptionChange extends React.Component {
    render() {
        return (
            <Modal show={!!this.props.subscriptionToChange} onHide={this.props.cancelAction}>
                <Modal.Header closeButton>
                    <Modal.Title>Change Subscription</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ErrorAlert message={this.props.errorMessage} onClose={this.props.clearErrorAction} delay={2000}/>
                    <Form.Group controlId="subscriptionChange">
                        <Form.Check>
                            <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1"
                                   value="PAID" defaultChecked={this.props.subscriptionToChange?.status === 'PAID'}
                                   onChange={
                                       e => {this.props.subscriptionToChange.status = e.currentTarget.value
                                       }}
                            />
                            <label className="form-check-label" htmlFor="exampleRadios1">
                                    Paid
                                </label>
                        </Form.Check>
                        <Form.Check className="form-check">
                            <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios2"
                                   value="UNPAID" defaultChecked={this.props.subscriptionToChange?.status === 'UNPAID'}
                                   onChange={e => {this.props.subscriptionToChange.status = e.currentTarget.value}}
                            />
                                <label className="form-check-label" htmlFor="exampleRadios2">
                                    Unpaid
                                </label>
                        </Form.Check>
                    </Form.Group>

                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.props.performChangeAction}>Update</Button>
                    <Button variant="secondary" onClick={this.props.cancelAction}>Cancel</Button>
                </Modal.Footer>
            </Modal>
        )
    }
}
