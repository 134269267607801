import React, {Component} from 'react';
import {Link, Redirect} from "react-router-dom";
import {Button, OverlayTrigger, Table, Tooltip} from "react-bootstrap";
import {deletePage, listPages} from '../services/pageService';
import {listFolders, movePageToFolder} from '../services/folderService';
import moment from 'moment';
import {FolderSymlink, Trash} from "react-bootstrap-icons";
import ErrorAlert from '../components/ErrorAlert'
import SuccessAlert from '../components/SuccessAlert'
import DeletePage from '../components/pages/delete'
import MovePage from '../components/pages/move'
import {Helmet} from "react-helmet";

export default class Pages extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pages: null,
            folders: [],
            pageToDelete: null,
            pageToMove: null,
            destFolderId: null,
            persistentErrorMessage: null,
            temporaryErrorMessage: null,
            successMessage: null,
            deletePageErrorMessage: null,
            movePageErrorMessage: null
        }
    }

    async componentDidMount() {
        try {
            const pages = await listPages();
            this.setState({pages: pages});
        } catch (err) {
            this.setState({
                persistentErrorMessage: `Problem getting pages: ${err.toString()}`,
                pages: []
            })
        }
    }

    performMove = async () => {
        try {
            await movePageToFolder(this.state.pageToMove.pageId, this.state.destFolderId);
        } catch (err) {
            this.setState({
                movePageErrorMessage: `Error moving page '${this.state.pageToMove.title}': ${err.toString()}`,
            })
            return;
        }
        try {
            const pages = await listPages();
            this.setState({pages})
        } catch (err) {
            this.setState({temporaryErrorMessage: `Unable to reload pages after move page: ` + err.toString()});
        }
        this.setState({
            successMessage: `Moved page '${this.state.pageToMove.title}'`,
            pageToMove: null,
            destFolderId: null
        });
    }

    deleteClick = (deletePageId, deletePageTitle) => {
        this.setState({
            pageToDelete: {pageId: deletePageId, title: deletePageTitle}
        })
    }

    moveClick = async (movePageId, movePageTitle, movePageCurrentFolderId) => {
        try {
            const folders = await listFolders();

            this.setState({
                pageToMove: {
                    pageId: movePageId,
                    title: movePageTitle,
                    currentFolderId: movePageCurrentFolderId,
                },
                folders
            })
        } catch (err) {
            this.setState({temporaryErrorMessage: `Unable to load folders: ${err.toString()}`})
        }

    }

    setDestFolderId = (e) => {
        console.log(e.target.value)
        this.setState({destFolderId: e.target.value})
    }

    performDeleteClick = async (event) => {
        try {
            await deletePage(this.state.pageToDelete.pageId);
        } catch (err) {
            this.setState({
                deletePageErrorMessage: `Error deleting page '${this.state.pageToDelete.title}': ${err.toString()}`,
            })
            return;
        }
        try {
            const pages = await listPages();
            this.setState({pages})
        } catch (err) {
            this.setState({temporaryErrorMessage: `Unable to reload pages after delete page: ` + err.toString()});
        }
        this.setState({
            pageToDelete: null,
            successMessage: `Deleted page '${this.state.pageToDelete.title}'`
        });
    }


    cancelDelete = (event) => {
        this.setState({pageToDelete: null})
    }

    cancelMove = (event) => {
        this.setState({
            pageToMove: null,
            destFolderId: null
        })
    }

    renderTooltip = (text, props) => (
        <Tooltip id="button-tooltip" {...props}>
            {text}
        </Tooltip>
    );

    clearPersistentErrorMessage = () => {
        this.setState({
            persistentErrorMessage: null,
        })
    };

    clearTemporaryErrorMessage = () => {
        this.setState({
            temporaryErrorMessage: null,
        })
    };

    clearSuccessMessage = () => {
        this.setState({
            successMessage: null,
        })
    };

    clearDeletePageErrorMessage = () => {
        this.setState({
            deletePageErrorMessage: null,
        })
    };

    clearMovePageErrorMessage = () => {
        this.setState({
            movePageErrorMessage: null,
        })
    };

    render() {

        const divStyle = {
            width: '100%',
            height: '800px',
            backgroundImage: "linear-gradient(to bottom right,#eae6ff, #fff)",
            backgroundSize: 'cover'
        };


        if (!this.props.authentication) {
            return <Redirect to='/'/>
        } else {
            const showFolders = this.props.authentication?.organisation?.status === 'PAID' // extract this somewhere common if we do this again
            return (

                this.state.pages ? (

                    this.state.pages.length ? (
                        <div style={divStyle}>
                            <Helmet>
                                <title>Ovalview | Pages</title>
                            </Helmet>
                            <ErrorAlert message={this.state.persistentErrorMessage}
                                        onClose={this.clearPersistentErrorMessage} delay={0}/>
                            <ErrorAlert message={this.state.temporaryErrorMessage}
                                        onClose={this.clearTemporaryErrorMessage} delay={3000}/>
                            <SuccessAlert message={this.state.successMessage} onClose={this.clearSuccessMessage}/>
                            <div className="container-fluid w-75">
                                <br/>
                                <div className="row">
                                    <h3>Pages for user: {this.props.authentication.name}</h3>
                                </div>
                                <div className="row">
                                    <div>
                                        <Link to="/pages/new" className="btn btn-primary mb-2">New Page</Link>
                                    </div>
                                </div>
                                <div className="row">
                                    <Table striped bordered hover aria-label={'pagesTable'}>
                                        <thead>
                                        <tr>
                                            <th>Title</th>
                                            <th>Last Updated</th>
                                            <th>Folder</th>
                                            <th>Actions</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            this.state.pages.map((page, index) => (
                                                <tr id={`page${index}`} key={page.id} aria-label={`row${index}`}>
                                                    <td><Link to={`/pages/${page.id}`}>{page.title}</Link></td>
                                                    <td>{moment(page.modifiedAt).fromNow()}</td>
                                                    <td>
                                                        {page.folderName}
                                                    </td>
                                                    <td>
                                                        {
                                                            showFolders
                                                                ?
                                                                <OverlayTrigger
                                                                    placement="top"
                                                                    delay={{show: 250, hide: 200}}
                                                                    overlay={this.renderTooltip('Move page')}
                                                                >

                                                                    <Button aria-label={"movePage"}
                                                                            onClick={() => this.moveClick(page.id, page.title, page.folderId)}
                                                                            variant="outline-primary" size="sm"
                                                                            align="right"
                                                                            className={"mr-1"}>
                                                                        <FolderSymlink/>
                                                                    </Button>

                                                                </OverlayTrigger> : ''
                                                        }
                                                        <OverlayTrigger
                                                            placement="top"
                                                            delay={{show: 250, hide: 200}}
                                                            overlay={this.renderTooltip('Delete page')}
                                                        >
                                                            <Button aria-label={"deletePage"}
                                                                    onClick={() => this.deleteClick(page.id, page.title)}
                                                                    variant="outline-danger"
                                                                    size="sm">
                                                                <Trash/>
                                                            </Button>

                                                        </OverlayTrigger>


                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                            <DeletePage pageToDelete={this.state.pageToDelete}
                                        cancelAction={this.cancelDelete}
                                        performDeleteAction={this.performDeleteClick}
                                        errorMessage={this.state.deletePageErrorMessage}
                                        clearErrorAction={this.clearDeletePageErrorMessage}/>

                            <MovePage pageToMove={this.state.pageToMove}
                                      folders={this.state.folders}
                                      cancelAction={this.cancelMove}
                                      performMoveAction={this.performMove}
                                      destFolderId={this.state.destFolderId}
                                      onFolderChangeAction={this.setDestFolderId}
                                      errorMessage={this.state.movePageErrorMessage}
                                      clearErrorAction={this.clearMovePageErrorMessage}/>
                        </div>
                    ) : <div>
                        <ErrorAlert message={this.state.persistentErrorMessage}
                                    onClose={this.clearPersistentErrorMessage} delay={0}/>
                        <ErrorAlert message={this.state.temporaryErrorMessage}
                                    onClose={this.clearTemporaryErrorMessage} delay={3000}/>
                        <SuccessAlert message={this.state.successMessage} onClose={this.clearSuccessMessage}/>
                        <div className="row justify-content-around">
                            <div className="col" align="right">
                                <h2>Create your first page</h2>
                            </div>
                            <div className="col" align="right">
                            </div>
                        </div>
                        <div className="row justify-content-around">

                            <div className="col"/>

                            <div className="col-4" align="center">
                                <img src="siteimages/selectImage.png" className="img-fluid d-block w-75"
                                     alt="Colour Coded"/>
                            </div>
                            <div className="col"/>
                        </div>
                        <div className="row justify-content-around">
                            <div className="col"/>

                            <div className="col" align="center">
                                <p className="lead" align="center">
                                    <Link to="/pages/new" className="btn btn-primary btn-lg">Upload image
                                        background</Link>
                                </p>
                            </div>
                            <div className="col"/>
                        </div>
                        <br/>


                    </div>
                ) : <div style={divStyle}>
                    <p>loading pages...</p>
                </div>
            )
        }
    }
}
