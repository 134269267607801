import React from "react";
import {Button, Modal} from "react-bootstrap";
import ErrorAlert from "../ErrorAlert";

export default class DeletePage extends React.Component {
    render() {
        return (
            <Modal show={!!this.props.pageToDelete} onHide={this.props.cancelAction}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Page</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ErrorAlert message={this.props.errorMessage} onClose={this.props.clearErrorAction} delay={2000}/>
                    <p>Are you sure you want to delete page '{this.props.pageToDelete?.title}'?</p>
                    <hr/>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={this.props.performDeleteAction}>Delete</Button>
                    <Button variant="secondary" onClick={this.props.cancelAction}>Cancel</Button>
                </Modal.Footer>
            </Modal>
        )
    }
}
