import jwksClient from "jwks-rsa";
import config from "../config/config";
import jwt from "jsonwebtoken";

const verifyToken = async (token) => {
    //get userpool public key
    const client = jwksClient({
        jwksUri: `https://cognito-idp.${config.cognito.REGION}.amazonaws.com/${config.cognito.USER_POOL_ID}/.well-known/jwks.json`
    });

    function getKey(header, callback) {
        client.getSigningKey(header.kid, function (err, key) {
            var signingKey = key.publicKey || key.rsaPublicKey;
            callback(null, signingKey);
        });
    }

    return new Promise((resolve, reject) => {
        jwt.verify(token, getKey, {audience: config.cognito.APP_CLIENT_ID}, function (err, decodedToken) {
            if (err) {
                reject(new Error("token verification failed - " + err));
            }
            console.log(`new token wil expire at: ${new Date(decodedToken.exp*1000).toISOString()}`);
            resolve(token);
        });
    });
};

export default verifyToken;
