import React, {Component} from 'react';
import {createJiraConfig, getJiraConfig} from '../../services/jiraService';
import ErrorAlert from "../../components/ErrorAlert";
import {Button, Card, Form, Modal, Table, Alert} from "react-bootstrap";
import SuccessAlert from "../SuccessAlert";
import {InfoCircle} from "react-bootstrap-icons";


export default class JiraConfig extends Component {
    constructor(props) {
        super(props);
        this.state = {
            jiraConfig: null,
            
            showConfigureModal: false,
            configureJiraError: false,
            
            newJiraDetails: null,
            
            persistentErrorMessage: null,
            temporaryErrorMessage: null,
            successMessage: null
        }
    }

    async componentDidMount() {
        try {
            const jiraConfigResponse = await getJiraConfig(this.props.org.id);
            this.setState({
                jiraConfig: jiraConfigResponse.config.length ? jiraConfigResponse.config[0] : {}
            });
        } catch (error) {
            this.setState({
                persistentErrorMessage: `Problem getting Jira Config: ${error.toString()}`
            });
        }
    }

    clearTemporaryErrorMessage = () => {
        this.setState({
            temporaryErrorMessage: null,
        })
    };

    clearSuccessMessage = () => {
        this.setState({
            successMessage: null,
        })
    };

    configureJiraClicked = async () => {
        this.setState({
            showConfigureModal: true,
            newJiraDetails: this.state.jiraConfig
        });
    }

    cancelConfigureJira = async () => {
        this.setState({
            showConfigureModal: false,
            newJiraDetails: null
        });
        this.clearConfigureJiraError();
    }

    clearConfigureJiraError = (event) => {
        this.setState({configureJiraError: null});
    }

    jiraProjectKeyChanged = (event) => {
        this.setState({newJiraDetails: {...this.state.newJiraDetails, projectKey: event.target.value}});
    }

    jiraHostChanged = (event) => {
        this.setState({newJiraDetails: {...this.state.newJiraDetails, host: event.target.value}});
    }

    jiraUsernameChanged = (event) => {
        this.setState({newJiraDetails: {...this.state.newJiraDetails, username: event.target.value}});
    }

    jiraPasswordChanged = (event) => {
        this.setState({newJiraDetails: {...this.state.newJiraDetails, password: event.target.value}});
    }

    configureJira = async () => {
        try {
            await createJiraConfig(this.props.org.id, this.state.newJiraDetails.projectKey, this.state.newJiraDetails.host, this.state.newJiraDetails.username, this.state.newJiraDetails.password );
            this.setState({
                showConfigureModal: false,
                jiraConfig: this.state.newJiraDetails,
                newJiraDetails: null,
                successMessage: `Successfully configured Jira`
            })
        } catch (e) {
            if (e.response?.data?.error) {
                this.setState({configureJiraError: e.response.data.error});
            } else {
                //Not a recognised error, just show what we can
                this.setState({configureJiraError: `Problem configuring Jira: ${e.toString()}`});
            }
        }
    }
    

    render() {
        return (
            <div>
                <Card className={"mt-2"}>

                    <Card.Body>
                        <h5>Jira</h5>
                        {!this.state.jiraConfig && !this.state.persistentErrorMessage && <p>loading Jira Config...</p>}
                        <ErrorAlert message={this.state.persistentErrorMessage} delay={0}/>
                        <ErrorAlert message={this.state.temporaryErrorMessage}
                                    onClose={this.clearTemporaryErrorMessage} delay={3000}/>
                        <SuccessAlert message={this.state.successMessage} onClose={this.clearSuccessMessage}/>
                        {this.state.jiraConfig &&
                        <div>
                            {this.state.jiraConfig.host ? (
                                <Table borderless size="sm">
                                    <tbody>
                                    <tr>
                                        <td><b>Project key</b></td>
                                        <td>{this.state.jiraConfig.projectKey}</td>
                                    </tr>
                                    <tr>
                                        <td><b>Host</b></td>
                                        <td>{this.state.jiraConfig.host}</td>
                                    </tr>
                                    </tbody>
                                </Table>
                            ) : <p>Jira not configured</p>}
                            <Button className={"mt-2"} disabled={this.props.disabled}
                                    onClick={this.configureJiraClicked}>
                                Configure Jira
                            </Button>
                        </div>
                        }

                    </Card.Body>
                </Card>
                <Modal show={this.state.showConfigureModal} onHide={this.cancelConfigureJira} aria-label={'configureJiraModal'}
                       backdrop="static">
                    <Modal.Header closeButton>
                        <Modal.Title>Configure Jira</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <ErrorAlert message={this.state.configureJiraError} delay={3000}
                                    onClose={this.clearConfigureJiraError}/>
                        <Card className={'my-2'}>
                            <Form className={'m-2'}>
                                <Alert key='jiraConfigExplanation' variant='info'>
                                    <InfoCircle/> Your Jira project must have 'Story' and 'Subtask' issue types
                                </Alert>
                                <Form.Group>
                                    <b>Project Key</b>
                                    <Form.Control type="input" placeholder="Jira project key" defaultValue={this.state.jiraConfig?.projectKey}
                                                  onChange={this.jiraProjectKeyChanged} aria-label={'configureProjectKey'}/>

                                    <b>Host</b>
                                    <Form.Control type="input" placeholder="e.g. https://myco.atlassian.net" defaultValue={this.state.jiraConfig?.host}
                                                  onChange={this.jiraHostChanged} aria-label={'configureHost'}/>

                                    <b>API user</b>
                                    <Form.Control type="input" placeholder="e.g. ovalview@myco.net" defaultValue={this.state.jiraConfig?.username}
                                                  onChange={this.jiraUsernameChanged} aria-label={'configureUsername'}/>

                                    <b>API Key</b>
                                    <Form.Control type="input" placeholder="user API Key" defaultValue={this.state.jiraConfig?.password}
                                                  onChange={this.jiraPasswordChanged} aria-label={'configurePassword'}/>
                                </Form.Group>
                            </Form>
                        </Card>
                    </Modal.Body>
                    <Modal.Footer>
                
                        <Button variant="secondary" onClick={this.cancelConfigureJira}>Cancel</Button>
                        <Button variant="primary" disabled={!this.state.newJiraDetails || (this.state.newJiraDetails && (!this.state.newJiraDetails.projectKey || !this.state.newJiraDetails.host || !this.state.newJiraDetails.username || !this.state.newJiraDetails.password))}
                                onClick={this.configureJira}>
                            Save
                        </Button>
                    </Modal.Footer>
                </Modal>

            </div>

        );
    }
}
