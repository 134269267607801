import React from 'react';
import {Redirect} from "react-router-dom";
import config from "../config/config";
import verifyToken from "../lib/JwtVerifier";
import {getUserInfo} from "../services/userService";

class LoginCallback extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            redirectUrl: null
        };
    }

    async componentDidMount() {
        // Verify state matches
        const urlParams = new URLSearchParams(window.location.search);

        const code = urlParams.get('code');
        const state = urlParams.get('state');

        if ( sessionStorage.getItem("pkce_state") !== state) {
            this.setState({error: "Invalid state"});
        } else {

            const code_verifier = sessionStorage.getItem('code_verifier');
            const callbackUrl = config.redirects.signIn;
                
            const url = `https://${config.cognito.DOMAIN}/oauth2/token?grant_type=authorization_code&client_id=${config.cognito.APP_CLIENT_ID}&code_verifier=${code_verifier}&redirect_uri=${callbackUrl}&code=${code}`;

            // Fetch OAuth2 tokens from Cognito
            await fetch(url, {
                method: 'post',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            })
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    // Verify id_token
                    return verifyToken(data.id_token)
                        .then(() => {
                            localStorage.setItem('token', data.id_token);
                            localStorage.setItem('refresh_token', data.refresh_token);
                            return data;
                        })
                        .catch((err) => {
                            console.error("error validating token: " + err);
                            return Promise.reject(err);
                        });
                }).then(async (tokens) => {
                    // now Fetch from /user_info
                    const userInfoUrl = `https://${config.cognito.DOMAIN}/oauth2/userInfo`;

                    await fetch(userInfoUrl, {
                        method: 'post',
                        headers: {
                            'authorization': 'Bearer ' + tokens.access_token
                        }
                    })
                        .then((response) => {
                            return response.json();
                        })
                        .then(async (data) => {
                            let userInfo = await getUserInfo();
                            //register the successful sign in
                            this.props.onSignIn(data, userInfo.features, userInfo.organisation)
                            //remove session vars
                            sessionStorage.removeItem("pkce_state");
                            sessionStorage.removeItem("code_verifier");
                            sessionStorage.removeItem("code_challenge");

                            if (sessionStorage.getItem('stashedPage')) {
                                console.log('found stashed page after logging, will redirect to /pages/new')
                                this.setState({redirectUrl: '/pages/new'});
                            } else {
                                this.setState({redirectUrl: '/'});
                            }
                        });
                })
                .catch((err) => {
                    console.error("error completing sign in: " + err);
                    this.setState({error: `problem with login: ${err}`})
                });
        }
    }

    render() {
        if (this.props.authentication && this.state.redirectUrl) {
            return <Redirect to={this.state.redirectUrl}/>
        } else if (this.state.error) {
            return <div className={"container"}>
                <h3>Problem signing in</h3>
                <p>{this.state.error}</p>
            </div>
        } else {
            return <div className={"container"}>
                <p>Completing sign in...</p>
            </div>
        }
    }
}

export default LoginCallback

