import {Button, Card, Form, OverlayTrigger, Tooltip} from "react-bootstrap";
import {DashCircle, Files, PlusCircle, Trash} from "react-bootstrap-icons";
import React from "react";
import {STATES} from '../../lib/PageConstants'


export default class OvalTaskPane extends React.Component {

    renderTooltip = (text, props) => (
        <Tooltip id="button-tooltip" {...props}>
            {text}
        </Tooltip>
    );

    performActionOnCanvasObjects(actionName, action) {
        const object = this.props.canvas.getActiveObject();
        if (!object) {
            console.error(`no object selected to ${actionName}`)
            return
        }

        if (object.type === 'ovalTask') {
            console.log(`performing ${actionName} on single item`);
            action(object);
            console.log(`object.text is ${object.text}`)
            object.text.dirty = true;
        } else if (object.type === 'activeSelection') {
            console.log(`found selection to perform ${actionName} on`);
            object.getObjects().forEach(subObject => {
                console.log(`performing ${actionName} on sub object`);
                action(subObject)
                subObject.dirty = true
                subObject.text.dirty = true;
            })
        }
        object.dirty = true

        this.props.canvas.renderAll()
    }
    
    deleteObject = () => {
        this.performActionOnCanvasObjects('delete', object => {this.props.canvas.remove(object)})
    }

    processFontSizeChange(change) {
        this.performActionOnCanvasObjects(`changing text size by ${change}`, object => {
            const currentSize = object.text.fontSize
            const newSize = currentSize + change
            if (newSize < 1 || newSize > 100) {
                console.log('min / max size reached')
            } else {
                object.text.fontSize = newSize
            }
        })
    }

    AvatarChanged = (e) => {
        this.performActionOnCanvasObjects(`set avatar to ${e.target.value}`, object => {
            object.setAvatar(this.props.assignees.find(assignee => assignee.id === e.target.value) || null);
        })
        this.setState({canvas: this.props.canvas})
    }

    stateChanged = (e) => {
        this.performActionOnCanvasObjects(`set state to ${e.target.value}`, object => {
            object.setState(STATES[e.target.value]);
        })
        this.setState({canvas: this.props.canvas}) 
    }

     render() {
        return <Card className={"mt-1"}>

             <Card.Header>
                 Oval properties
             </Card.Header>
             <Card.Body>
                 <Form>
                     <Form.Group controlId="actions">

                         <Form.Label>Actions</Form.Label><br/>
                         <OverlayTrigger
                             placement="left"
                             delay={{show: 250, hide: 400}}
                             overlay={this.renderTooltip('Delete oval')}
                         >
                             <Button onClick={() => this.deleteObject()}
                                     variant="secondary"><Trash/></Button>

                         </OverlayTrigger>
                         <OverlayTrigger
                             placement="right"
                             delay={{show: 250, hide: 400}}
                             overlay={this.renderTooltip('Clone oval')}
                         >
                             <Button className={"ml-1"} onClick={() => console.log('Clone not implemented yet')}
                                     variant="secondary"><Files/></Button>

                         </OverlayTrigger>
                     </Form.Group>
                     <Form.Group controlId="text">

                         <Form.Label>Text</Form.Label><br/>

                         <OverlayTrigger
                             placement="left"
                             delay={{show: 250, hide: 400}}
                             overlay={this.renderTooltip('Decrease font size')}
                         >
                             <Button onClick={() => this.processFontSizeChange(-1)}
                                     variant="secondary"><DashCircle/></Button>

                         </OverlayTrigger>
                         <OverlayTrigger
                             placement="right"
                             delay={{show: 250, hide: 400}}
                             overlay={this.renderTooltip('Increase font size')}
                         >
                             <Button onClick={() => this.processFontSizeChange(1)}
                                     className={"ml-1"}
                                     variant="secondary"><PlusCircle/></Button>

                         </OverlayTrigger>
                     </Form.Group>

                     { this.props.assignees &&
                     <Form.Group controlId="assignee">
                         <Form.Label>Assignee</Form.Label>
                         <Form.Control as="select" onChange={this.AvatarChanged}
                                       value={this.props.canvas.getActiveObject().assignee || ''}>
                             <option value={'0'} key={'0'}>None</option>
                             {this.props.assignees.map((assignee) => (
                                 <option value={assignee.id} key={assignee.id}>{assignee.name}</option>
                             ))}
                         </Form.Control>
                     </Form.Group> }

                     <Form.Group controlId="status">
                         <Form.Label>Status</Form.Label><br/>
                         <Form.Control as="select" onChange={this.stateChanged}
                                       value={this.props.canvas.getActiveObject().state}>
                             {Object.keys(STATES).map((key) => (
                                 <option value={key} key={key}>{STATES[key].text}</option>
                             ))}
                         </Form.Control>
                     </Form.Group>
                 </Form>

             </Card.Body>
        </Card>;
    }
}
