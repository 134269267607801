import {Button, Card, Form, Modal,} from "react-bootstrap";
import React, {Component} from 'react';
import {Redirect} from "react-router-dom";
import {createOrg, getOrgs} from '../services/orgService';
import ErrorAlert from "../components/ErrorAlert";

export default class CreateOrganisation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            orgId: null,
            redirectUrl: null,
            name: null,
            createOrgError: null,
        }
    }

    async componentDidMount() {
        if (!this.props.authentication) {
            console.warn('Not logged in, redirecting')
            this.setState({redirectUrl: '/'});
            return;
        }
        if (this.props.authentication.organisation) {
            console.warn('user already has an org, redirecting to it')
            this.setState({redirectUrl: `/organisations/${this.props.authentication.organisation.id}`});
            return;
        }
    }

    cancelCreate = async () => {
        this.props.history.goBack();
    }

    nameChanged = (event) => {
        this.setState({name: event.target.value});
    }

    create = async () => {
        try {
            const newOrgId = await createOrg(this.state.name);
            const orgs = await getOrgs();
            const org = orgs.find(o => (o.id === newOrgId));
            this.props.onOrgCreate(org);
            this.setState({redirectUrl: `/organisations/${newOrgId}`})
        } catch (e) {
            this.setState({createOrgError: `Problem creating organisation: ${e.toString()}`});
        }
    }

    clearCreateOrgError = () => {
        this.setState({createOrgError: null})
    }

    render() {
        if (this.state.redirectUrl) {
            return <Redirect to={this.state.redirectUrl}/>
        } else {
            return (

                <Modal show onHide={this.cancelCreate} aria-label={'createOrganisationModal'} backdrop="static">
                    <Modal.Header closeButton>
                        <Modal.Title>Create A New Organisation</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <ErrorAlert message={this.state.createOrgError} delay={3000}
                                    onClose={this.clearCreateOrgError}/>
                        <Card className={'my-2'}>
                            <Form className={'m-2'}>
                                <Form.Group>
                                    <b>Org Name</b>
                                    <Form.Control type="input" placeholder="Enter a name for the Organisation"
                                                  onChange={this.nameChanged} aria-label={'createOrgName'}/>
                                </Form.Group>
                            </Form>
                        </Card>
                    </Modal.Body>
                    <Modal.Footer>

                        <Button variant="secondary" onClick={this.cancelCreate}>Cancel</Button>
                        <Button variant="primary" disabled={!this.state.name}
                                onClick={this.create}>
                            Create
                        </Button>
                    </Modal.Footer>
                </Modal>
            );
        }
    }
}
