import React, {Component} from 'react';
import {addUserToOrg, getOrgMembers, removeUserFromOrg} from '../../services/orgService';
import ErrorAlert from "../../components/ErrorAlert";
import {Button, Card, Form, Modal, OverlayTrigger, Table, Tooltip, Alert} from "react-bootstrap";
import {Plus, Trash} from "react-bootstrap-icons";
import SuccessAlert from "../SuccessAlert";

export default class Members extends Component {
    constructor(props) {
        super(props);
        this.state = {
            members: null,
            emailToAdd: null,
            emailToDelete: null,

            showCreateModal: false,

            addUserToOrgError: null,
            removeUserFromOrgError: null,
            persistentErrorMessage: null,
            temporaryErrorMessage: null,
            successMessage: null,
        }
    }

    async componentDidMount() {
        try {
            const membersResponse = await getOrgMembers(this.props.org.id);
            this.setState({
                members: membersResponse.members,
            });
        } catch (error) {
            this.setState({
                persistentErrorMessage: `Problem getting members: ${error.toString()}`
            });
        }
    }

    cancelAddUser = async () => {
        this.setState({
            showCreateModal: false,
            emailToAdd: null
        });
        this.clearAddUserToOrgError();
    }

    addClicked = async () => {
        this.setState({showCreateModal: true});
    }

    emailChanged = (event) => {
        this.setState({emailToAdd: event.target.value});
    }

    addUserToOrg = async () => {
        try {
            await addUserToOrg(this.props.org.id, this.state.emailToAdd);
            await this.refreshMembers();
            this.setState({
                showCreateModal: false,
                emailToAdd: null,
                successMessage: `Successfully added ${this.state.emailToAdd} to org`
            })
        } catch (e) {
            if (e.response?.data?.error === 'Invalid value') {
                this.setState({addUserToOrgError: `Invalid email address`});
            } else if (e.response?.data?.error) {
                this.setState({addUserToOrgError: e.response.data.error});
            } else {
                //Not a recognised error, just show what we can
                this.setState({addUserToOrgError: `Problem adding user to org: ${e.toString()}`});
            }
        }
    }

    cancelDelete = async () => {
        this.setState({emailToDelete: null});
        this.clearRemoveUserFromOrgError();
    }

    deleteClicked = (email) => {
        this.setState({
            emailToDelete: email,
        })
    };

    removeUserFromOrg = async () => {
        try {
            await removeUserFromOrg(this.props.org.id, this.state.emailToDelete);
            await this.refreshMembers();
            this.setState({
                emailToDelete: null,
                successMessage: `Successfully removed ${this.state.emailToDelete} from org`
            })
        } catch (e) {
            if (e.response?.data?.error === 'Invalid value') {
                this.setState({removeUserFromOrgError: `Invalid email address`});
            } else if (e.response?.data?.error) {
                this.setState({removeUserFromOrgError: e.response.data.error});
            } else {
                //Not a recognised error, just show what we can
                this.setState({removeUserFromOrgError: `Problem removing user from org: ${e.toString()}`});
            }
        }
    }

    refreshMembers = async () => {
        try {
            const membersResponse = await getOrgMembers(this.props.org.id);
            this.setState({
                members: membersResponse.members,
            });
        } catch (e) {
            this.setState({temporaryErrorMessage: `Unable to reload members: ` + e.toString()});
        }
    }


    clearAddUserToOrgError = (event) => {
        this.setState({addUserToOrgError: null});
    }
    clearRemoveUserFromOrgError = (event) => {
        this.setState({removeUserFromOrgError: null});
    }
    clearTemporaryErrorMessage = () => {
        this.setState({
            temporaryErrorMessage: null,
        })
    };
    clearSuccessMessage = () => {
        this.setState({
            successMessage: null,
        })
    };

    renderTooltip = (text, props) => (
        <Tooltip id="button-tooltip" {...props}>
            {text}
        </Tooltip>
    );

    render() {
        return (
            <div>
                <Card>

                    <Card.Body>
                        {!this.state.members && !this.state.persistentErrorMessage && <p>loading members...</p>}
                        <ErrorAlert message={this.state.persistentErrorMessage} delay={0}/>
                        <ErrorAlert message={this.state.temporaryErrorMessage}
                                    onClose={this.clearTemporaryErrorMessage} delay={3000}/>
                        <SuccessAlert message={this.state.successMessage} onClose={this.clearSuccessMessage}/>
                        {this.state.members &&
                        <div>
                            <h5>Users</h5>
                            {this.props.isOwner || <Alert key={'owner-info'} variant={'info'}>You cannot modify users. You are not the org owner</Alert>}
                            {this.state.members.length ? (
                                <Table striped bordered hover size="sm" className={"mt-1"}>
                                    <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Action</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.state.members.map((member, index) => (
                                        <tr id={`member${index}`} key={member.userId}
                                            aria-label={`row${index}`}>
                                            <td>{member.name}</td>
                                            <td>{member.email}</td>
                                            <td>
                                                <OverlayTrigger
                                                    placement="top"
                                                    delay={{show: 250, hide: 200}}
                                                    overlay={this.renderTooltip('Remove user from org')}>
                                                    <Button
                                                        aria-label={'btnRemove'}
                                                        variant="outline-danger"
                                                        size="sm"
                                                        disabled={this.props.disabled || member.userId === this.props.org.ownerId}
                                                        onClick={() => {
                                                            this.deleteClicked(member.email)
                                                        }}>
                                                        <Trash/>
                                                    </Button>
                                                </OverlayTrigger>
                                            </td>
                                        </tr>))}
                                    </tbody>
                                </Table>) : <p>This org has no members</p>}
                            <OverlayTrigger
                                placement="right"
                                delay={{show: 250, hide: 200}}
                                overlay={this.renderTooltip('Add user to org')}>
                                <Button disabled={this.props.disabled} onClick={this.addClicked} aria-label={"btnAddUser"}>
                                    <Plus/>
                                </Button>
                            </OverlayTrigger>
                        </div>
                        }

                    </Card.Body>
                </Card>
                <Modal show={this.state.showCreateModal} onHide={this.cancelAddUser} aria-label={'addMemberModal'}
                       backdrop="static">
                    <Modal.Header closeButton>
                        <Modal.Title>Add a user to org: {this.props.org.name}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <ErrorAlert message={this.state.addUserToOrgError} delay={3000}
                                    onClose={this.clearAddUserToOrgError}/>
                        <Card className={'my-2'}>
                            <Form className={'m-2'}>
                                <Form.Group>
                                    <b>User</b>
                                    <Form.Control type="input" placeholder="Email address"
                                                  onChange={this.emailChanged} aria-label={'addUserEmail'}/>
                                </Form.Group>
                            </Form>
                        </Card>
                    </Modal.Body>
                    <Modal.Footer>

                        <Button variant="secondary" onClick={this.cancelAddUser}>Cancel</Button>
                        <Button variant="primary" disabled={!this.state.emailToAdd}
                                onClick={this.addUserToOrg}>
                            Add
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={!!this.state.emailToDelete} onHide={this.cancelDelete} aria-label={'removeMemberModal'} backdrop={'static'}>
                    <Modal.Header closeButton>
                        <Modal.Title>Remove user from org</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <ErrorAlert message={this.state.removeUserFromOrgError}
                                    onClose={this.clearRemoveUserFromOrgError} delay={2000}/>
                        <p>Are you sure you want to remove user '{this.state.emailToDelete}' from org?</p>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={this.removeUserFromOrg}>Remove</Button>
                        <Button variant="secondary" onClick={this.cancelDelete}>Cancel</Button>
                    </Modal.Footer>
                </Modal>
            </div>

        );
    }
}
