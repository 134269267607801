import React from "react";
import {Button, Form, Modal} from "react-bootstrap";
import ErrorAlert from "../ErrorAlert";

export default class MovePage extends React.Component {
    render() {
        return (
            <Modal show={!!this.props.pageToMove} onHide={this.props.cancelAction}>
                <Modal.Header closeButton>
                    <Modal.Title>Move Page</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ErrorAlert message={this.props.errorMessage} onClose={this.props.clearErrorAction} delay={2000}/>
                    <Form.Group controlId="movePage">
                        <Form.Label>Select Destination Folder for page '{this.props.pageToMove?.title}'</Form.Label>
                        <Form.Control as="select" onChange={this.props.onFolderChangeAction} value={this.props.destFolderId || this.props.pageToMove?.currentFolderId}>
                            {this.props.folders.map((folder) => (
                                <option value={folder.id} key={folder.id}>{folder.name}</option>
                            ))}
                        </Form.Control>
                    </Form.Group>

                    <hr/>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.props.performMoveAction} disabled={!this.props.destFolderId}>Move</Button>
                    <Button variant="secondary" onClick={this.props.cancelAction}>Cancel</Button>
                </Modal.Footer>
            </Modal>
        )
    }
}
