import React, {Component} from 'react';
import {Redirect} from "react-router-dom";
import {getOrgs} from '../services/orgService';
import ErrorAlert from "../components/ErrorAlert";
import {Alert, Button, Col, Container, Row} from "react-bootstrap";
import Members from "../components/organisation/Members"
import Assignees from "../components/organisation/Assignees"
import Folders from "../components/organisation/Folders"
import JiraConfig from "../components/organisation/JiraConfig"
import {Helmet} from "react-helmet";

export default class Organisation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            org: null,
            persistentErrorMessage: null,
            disabled: false,
            changeOwnerDisabled: true,
            folders: null
        }
    }

    async componentDidMount() {
        if (!this.props.authentication) {
            console.warn('Not logged in, redirecting')
            this.setState({redirectUrl: '/'});
            return;
        }
        try {
            const {orgId} = this.props.match.params;
            const orgs = await getOrgs(orgId);
            const org = orgs.find(o => o.id === orgId);
            if (org) {
                this.setState({org, disabled: org.status !== 'PAID'})
            } else {
                throw new Error(`Unable to find org ${orgId}`)
            }
        } catch (error) {
            this.setState({
                persistentErrorMessage: `Problem getting org: ${error.toString()}`
            });
        }
    }
    
    isOrgOwner = () => {
        return this.props.authentication.sub === this.state.org.ownerId
    }

    render() {
        if (this.state.redirectUrl) {
            return <Redirect to={this.state.redirectUrl}/>
        } else {
            return (
                <div>
                    <Helmet>
                        <title>Ovalview | Organisation</title>
                    </Helmet>
                    <ErrorAlert message={this.state.persistentErrorMessage} delay={0}/>
                    {!this.state.org && !this.state.persistentErrorMessage && <p>loading org...</p>}

                    {this.state.org && (
                        <Container className={"mt-4"}>
                            {this.state.disabled && <Alert variant='warning' aria-label={'paidWarningAlert'}>
                                Org functionality is disabled until your subscription is <Alert.Link
                                href="/banding">activated</Alert.Link>
                            </Alert>}
                            {this.state.org?.endDate && <Alert variant='info' aria-label={'freeTrialAlert'}>
                                Your org has a free trial enabled
                            </Alert>}
                            <Button className="float-right" disabled={this.state.changeOwnerDisabled}>Change Owner</Button>
                            <Row>
                                <h3>Org Name: {this.state.org.name}</h3>
                            </Row>
                            <Row className={"mt-4"}>
                                <Col xs={7}>
                                    <Members org={this.state.org} disabled={this.state.disabled || (!this.isOrgOwner())} isOwner={this.isOrgOwner()}/>
                                </Col>
                                <Col>
                                    <Folders org={this.state.org} disabled={this.state.disabled}/>
                                    <JiraConfig org={this.state.org} disabled={this.state.disabled || (!this.isOrgOwner())} isOwner={this.isOrgOwner()}/>
                                    <Assignees org={this.state.org} disabled={this.state.disabled || (!this.isOrgOwner())} isOwner={this.isOrgOwner()}/>
                                </Col>

                        </Row>
                        </Container>
                    )}

                </div>
            );
        }
    }
}
