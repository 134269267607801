import React, {Component} from 'react';
import {addAssigneeToOrg, getOrgAssignees, removeAssigneeFromOrg} from '../../services/orgService';
import ErrorAlert from "../../components/ErrorAlert";
import {Button, Card, Form, Modal, OverlayTrigger, Table, Tooltip, Alert} from "react-bootstrap";
import {Plus, Trash} from "react-bootstrap-icons";
import SuccessAlert from "../SuccessAlert";

export default class Assignees extends Component {
    constructor(props) {
        super(props);
        this.state = {
            assignees: null,
            nameToAdd: null,
            assigneeToDelete: null,
            assignedName: null,

            showCreateModal: false,

            addAssigneeToOrgError: null,
            removeAssigneeFromOrgError: null,
            persistentErrorMessage: null,
            temporaryErrorMessage: null,
            successMessage: null,
        }
    }

    async componentDidMount() {
        try {
            const assigneeResponse = await getOrgAssignees(this.props.org.id);
            this.setState({
                assignees: assigneeResponse.assignees,
            });
        } catch (error) {
            this.setState({
                persistentErrorMessage: `Problem getting assignees: ${error.toString()}`
            });
        }
    }

    cancelAddAssignee = async () => {
        this.setState({
            showCreateModal: false,
            nameToAdd: null
        });
        this.clearAddAssigneeToOrgError();
    }

    addClicked = async () => {
        this.setState({showCreateModal: true});
    }

    nameChanged = (event) => {
        this.setState({nameToAdd: event.target.value});
    }

    addAssigneeToOrg = async () => {
        try {
            await addAssigneeToOrg(this.props.org.id, this.state.nameToAdd);
            await this.refreshAssignees();
            this.setState({
                showCreateModal: false,
                nameToAdd: null,
                successMessage: `Successfully added ${this.state.nameToAdd} to org`
            })
        } catch (e) {
            if (e.response?.data?.error === 'Invalid value') {
                this.setState({addAssigneeToOrgError: `Invalid assignee value`});
            } else if (e.response?.data?.error) {
                this.setState({addAssigneeToOrgError: e.response.data.error});
            } else {
                //Not a recognised error, just show what we can
                this.setState({addAssigneeToOrgError: `Problem adding assignee to org: ${e.toString()}`});
            }
        }
    }

    cancelDelete = async () => {
        this.setState({assigneeToDelete: null});
        this.clearRemoveAssigneeFromOrgError();
    }

    deleteClicked = (id, name) => {
        this.setState({
            assigneeToDelete: id,
            assigneeName: name,
        })
    };
    // async function deleteClicked(id, name){
    //     this.setState({
    //         assigneeToDelete: id,
    //         assigneeName: name
    //     })
    // };

    removeAssigneeFromOrg = async () => {
        try {
            await removeAssigneeFromOrg(this.props.org.id, this.state.assigneeToDelete);
            await this.refreshAssignees();
            this.setState({
                assigneeToDelete: null,
                successMessage: `Successfully removed ${this.state.assigneeName} from org`
            })
        } catch (e) {
            if (e.response?.data?.error === 'Invalid value') {
                this.setState({removeAssigneeFromOrgError: `Invalid assignee address`});
            } else if (e.response?.data?.error) {
                this.setState({removeAssigneeFromOrgError: e.response.data.error});
            } else {
                //Not a recognised error, just show what we can
                this.setState({removeAssigneeFromOrgError: `Problem removing assignee from org: ${e.toString()}`});
            }
        }
    }

    refreshAssignees = async () => {
        try {
            const assigneesResponse = await getOrgAssignees(this.props.org.id);
            this.setState({
                assignees: assigneesResponse.assignees,
            });
        } catch (e) {
            this.setState({temporaryErrorMessage: `Unable to reload Assignees: ` + e.toString()});
        }
    }


    clearAddAssigneeToOrgError = (event) => {
        this.setState({addAssigneeToOrgError: null});
    }
    clearRemoveAssigneeFromOrgError = (event) => {
        this.setState({removeAssigneeFromOrgError: null});
    }
    clearTemporaryErrorMessage = () => {
        this.setState({
            temporaryErrorMessage: null,
        })
    };
    clearSuccessMessage = () => {
        this.setState({
            successMessage: null,
        })
    };

    renderTooltip = (text, props) => (
        <Tooltip id="button-tooltip" {...props}>
            {text}
        </Tooltip>
    );

    render() {
        return (
            <div>
                <Card className={"mt-2"}>

                    <Card.Body>
                        {!this.state.assignees && !this.state.persistentErrorMessage && <p>loading assignees...</p>}
                        <ErrorAlert message={this.state.persistentErrorMessage} delay={0}/>
                        <ErrorAlert message={this.state.temporaryErrorMessage}
                                    onClose={this.clearTemporaryErrorMessage} delay={3000}/>
                        <SuccessAlert message={this.state.successMessage} onClose={this.clearSuccessMessage}/>
                        {this.state.assignees &&
                        <div>
                            <h5>Assignees</h5>
                            {this.props.isOwner || <Alert key={'owner-info'} variant={'info'}>You cannot modify assignees. You are not the org owner</Alert>}
                            {this.state.assignees.length ? (
                                <Table striped bordered hover size="sm" className={"mt-1"}>
                                    <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Action</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.state.assignees.map((assignee, index) => (
                                        <tr id={`assignee${index}`} key={assignee.id}
                                            aria-label={`row${index}`}>
                                            <td>{assignee.name}</td>
                                            <td>
                                                <OverlayTrigger
                                                    placement="top"
                                                    delay={{show: 250, hide: 200}}
                                                    overlay={this.renderTooltip('Remove assignee from org')}>
                                                    <Button
                                                        aria-label={'btnRemove'}
                                                        variant="outline-danger"
                                                        size="sm"
                                                        data-name={assignee.name}
                                                        onClick={() => {
                                                            this.deleteClicked(assignee.id, assignee.name)
                                                        }}>
                                                        <Trash/>
                                                    </Button>
                                                </OverlayTrigger>
                                            </td>
                                        </tr>))}
                                    </tbody>
                                </Table>) : <p>This org has no Assignees</p>}
                            <OverlayTrigger
                                placement="right"
                                delay={{show: 250, hide: 200}}
                                overlay={this.renderTooltip('Add Assignee')}>
                                <Button disabled={this.props.disabled} onClick={this.addClicked} aria-label={"btnAddAssignee"}>
                                    <Plus/>
                                </Button>
                            </OverlayTrigger>
                        </div>
                        }

                    </Card.Body>
                </Card>
                <Modal show={this.state.showCreateModal} onHide={this.cancelAddAssignee} aria-label={'addAssigneeModal'}
                       backdrop="static">
                    <Modal.Header closeButton>
                        <Modal.Title>Add an assignee to org: {this.props.org.name}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <ErrorAlert message={this.state.addAssigneeToOrgError} delay={3000}
                                    onClose={this.clearAddAssigneeToOrgError}/>
                        <Card className={'my-2'}>
                            <Form className={'m-2'}>
                                <Form.Group>
                                    <b>Assignee</b>
                                    <Form.Control type="input" placeholder="name"
                                                  onChange={this.nameChanged} aria-label={'addAssigneeName'}/>
                                </Form.Group>
                            </Form>
                        </Card>
                    </Modal.Body>
                    <Modal.Footer>

                        <Button variant="secondary" onClick={this.cancelAddAssignee}>Cancel</Button>
                        <Button variant="primary" disabled={!this.state.nameToAdd}
                                onClick={this.addAssigneeToOrg}>
                            Add
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={!!this.state.assigneeToDelete} onHide={this.cancelDelete} aria-label={'removeAssigneeModal'} backdrop={'static'}>
                    <Modal.Header closeButton>
                        <Modal.Title>Remove assignee from org</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <ErrorAlert message={this.state.removeAssigneeFromOrgError}
                                    onClose={this.clearRemoveAssigneeFromOrgError} delay={2000}/>
                        <p>Are you sure you want to remove assignee {this.state.assigneeName} from org?</p>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={this.removeAssigneeFromOrg}>Remove</Button>
                        <Button variant="secondary" onClick={this.cancelDelete}>Cancel</Button>
                    </Modal.Footer>
                </Modal>
            </div>

        );
    }
}
