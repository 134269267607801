import React, {Component} from 'react';
import {Nav, Navbar, NavDropdown} from "react-bootstrap";
import {Link} from "react-router-dom";

class Header extends Component {

    render() {
        return (
            <Navbar bg="light" expand="sm" sticky="top">
                <Navbar.Brand as={Link} to="/">Ovalview</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto">
                        {
                            this.props.authentication
                                ? <NavDropdown title="Pages" id="collasible-nav-dropdown">
                                    <NavDropdown.Item as={Link} to="/pages">My Pages</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="/pages/new">Create Page</NavDropdown.Item>
                                </NavDropdown>
                                : <Nav.Link as={Link} to="/pages/new">Create Page</Nav.Link>
                        }
                        {
                            this.props.authentication &&
                                <NavDropdown title="Org" id="collasible-nav-dropdown">
                                    {this.props.authentication.organisation ? 
                                        <NavDropdown.Item as={Link} to={`/organisations/${this.props.authentication.organisation.id}`}>{this.props.authentication.organisation.name}</NavDropdown.Item> :
                                        <NavDropdown.Item as={Link} to="/createOrganisation">Create Org</NavDropdown.Item>
                                    }
                                </NavDropdown>

                        }
                        <Nav.Link as={Link} to="/howTo">How To</Nav.Link>
                        <Nav.Link as={Link} to="/contact">Contact</Nav.Link>

                        {
                            this.props.authentication?.features?.admin &&
                                <Nav.Link as={Link} to="/admin">Admin</Nav.Link>
                        }

                    </Nav>
                    <Nav>
                        {
                            this.props.authentication ?
                                <Nav.Link></Nav.Link>
                                :
                                <Link to="/login" className="btn btn-link">Log In</Link>
                        }
                    </Nav>
                    <Nav>
                        {
                            this.props.authentication ?
                                <NavDropdown title="Profile" id="collasible-nav-dropdown" alignRight={true}>
                                    <NavDropdown.Item as={Link} to="/logout">Sign out  ({this.props.authentication.name})</NavDropdown.Item>
                                </NavDropdown>
                                :
                                <Link to="/login" className="btn btn-primary">Sign up free</Link>
                        }
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        );

    }
}

export default Header;
