import React, {Component} from 'react';
import {Helmet} from "react-helmet";

class Videos extends Component {

        render() {

            const headerStyle = {
                backgroundColor: "#D8FFF7",
                backgroundImage: "linear-gradient(to bottom right,#eae6ff, #fff)",
            };

            return (

                <div>
                    <Helmet>
                        <title>Ovalview | Video guides</title>
                    </Helmet>
                    <div className="jumbotron" style={headerStyle}>
                        <h1 className="display-4 text-center">Ovalview - How To</h1>
                        <p className="lead text-center">brief video guides to get you started
                        </p>
                        <br/>
                        <p className="lead" align="center">
                            <button className="btn btn-primary btn-lg" onClick={this.props.history.goBack}>Take me back...</button>
                        </p>

                        <br/><br/><br/>
                        <div className="row justify-content-around">

                            <div className="col" align="center">
                                    <p className="display-4 text-center">What is this Ovalview?</p>
                                    <p className="display-5 text-center">An introduction to Ovalview</p>
                            </div>
                            <div className="col" align="center">
                                <iframe
                                    title="overview"
                                    width="853"
                                    height="480"
                                    src="https://www.youtube.com/embed/fZ-OOunODtc"/>
                            </div>
                        </div>

                    </div>
                </div>
            );
        }
}

export default Videos
