import config from "../config/config";

const axios = require('axios');

export const listPages = async () => {
    try {
        const response = await axios.get(config.backend_urls.pages);
        return response.data.pages;
    } catch (error) {
        console.error(`error listing pages: ${error}`);
        throw error;
    }
}

export const getPage = async (pageId) => {
    try {
        const response = await axios.get(`${config.backend_urls.pages}/${pageId}`);
        return response.data;
    } catch (error) {
        console.error(`error getting page ${pageId}: ${error}`);
        throw error;
    }
}

export const createPage = async (page, exportedImage) => {
    const pageTaskedTrimmed = await cleanTasks(page);
    const pageCleanedFields = await cleanNotes(pageTaskedTrimmed);
    let location;
    try {
        const response = await axios.post(`${config.backend_urls.pages}`, pageCleanedFields,
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
        location = response.headers['location'];
    } catch (error) {
        console.error(`error creating page: ${error}`);
        throw error;
    }
    if (exportedImage) {
        let pageId;
        try {
            pageId = location.substring(location.lastIndexOf('/') + 1)
            await axios.post(`${config.backend_urls.pages}/${pageId}/export`, {image: exportedImage}, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
        } catch (error) {
            console.error(`error uploading exported page ${pageId}: ${error}`);
            //Not rethrowing error. Just log the error when an s3 export fails
        }
    }
    return location;
}

export const updatePage = async (pageId, page, exportedImage) => {
    const pageTaskedTrimmed = await cleanTasks(page);
    const pageCleanedFields = await cleanNotes(pageTaskedTrimmed);
    try {
        await axios.put(`${config.backend_urls.pages}/${pageId}`, pageCleanedFields, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    } catch (error) {
        console.error(`error updating page ${pageId}: ${error}`);
        throw error;
    }
    try {
        await axios.post(`${config.backend_urls.pages}/${pageId}/export`, {image: exportedImage}, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    } catch (error) {
        console.error(`error uploading exported page ${pageId}: ${error}`);
        //Not rethrowing error. Just log the error when an s3 export fails
    }


}

export const deletePage = async (pageId) => {
    try {
        await axios.delete(`${config.backend_urls.pages}/${pageId}`);
    } catch (error) {
        console.error(`error deleting page ${pageId}: ${error}`);
        throw error;
    }
}

export const cleanTasks = async (page) => {
    // skip if no tasks to clean
    var jsonPage = page;
    if (page.constructor === String){
        jsonPage = JSON.parse(page);
    }

    if (!jsonPage.hasOwnProperty('tasks')){
        return page;
    }

    var tasks = [];
    for (const t of jsonPage.tasks){
        var newlineCleaned = t.text.replace(/\n/g,' ');
        var cleanString = newlineCleaned.replace(/[^a-zA-Z0-9 _£#$&\\?().:\\-]/g,'');
        var trimmedString = cleanString.substring(0,40);

        t.text = trimmedString;
        tasks.push(t);
    }
    jsonPage.tasks = tasks;
    return jsonPage;
}

export const cleanNotes = async (page) => {
    var jsonPage = page;

    if (page.constructor === String){
        jsonPage = JSON.parse(page);
    }

    // skip if no notes to clean
    if (!jsonPage.hasOwnProperty('notes')){
        return jsonPage;
    }

    var notes = [];
    for (const n of jsonPage.notes){
        var trimmedString = n.text.substring(0,100);

        n.text = trimmedString;
        notes.push(n);
    }
    jsonPage.notes = notes;
    return jsonPage;
}


axios.interceptors.request.use(function (config) {
    const token = localStorage.getItem('token');
    config.headers.Authorization = `Bearer ${token}`;
    return config;
}, function (error) {
    return Promise.reject(error);
});
