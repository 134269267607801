import config from "../config/config";

const axios = require('axios');

export const listFolders = async () => {
    try {
    const response = await axios.get(config.backend_urls.folders);
    return response.data;
    } catch (error) {
        console.error(`error listing folders: ${error}`);
        throw error;
    }
}

export const movePageToFolder = async (pageId, folderId) => {
    try {
        await axios.post(`${config.backend_urls.folders}/${folderId}/page/${pageId}`, null,
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
        return pageId;
    } catch (error) {
        console.error(`error moving page ${pageId} to folder ${folderId}: ${error}`);
        throw error;
    }
}

export const getFoldersByOrg = async (orgId) => {
    try {
        const response = await axios.get(`${config.backend_urls.folders}?orgId=${orgId}`,
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
        return response.data;
    } catch (error) {
        console.error(`error getting folders for org: ${error}`);
        throw error;
    }
}

export const createFolder = async (orgId, name) => {
    try {
        await axios.post(`${config.backend_urls.folders}`, {name},
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
    } catch (error) {
        console.error(`error creating folder: ${error}`);
        throw error;
    }
}

axios.interceptors.request.use(function (config) {
    const token = localStorage.getItem('token');
    config.headers.Authorization = `Bearer ${token}`;
    return config;
}, function (error) {
    return Promise.reject(error);
});
