import React, {Component} from "react";
import {Alert} from "react-bootstrap";

export default class ErrorAlert extends Component {
    componentDidUpdate(prevProps) {
        if (!prevProps.message && this.props.message && this.props.delay) {
            console.log(`errorAlert - found message (${this.props.message}). Will clear after ${this.props.delay}`)
            this.timerHandle = window.setTimeout(()=>{
                this.props.onClose()
            },this.props.delay)
        }
    }

    componentWillUnmount() {
        if (this.timerHandle) {
            clearTimeout(this.timerHandle);
            this.timerHandle = 0;
        }
    }
    
    render() {
        return (
            <Alert aria-label={'errorAlert'} key={'errorAlert'} variant={'danger'} show={!!this.props.message} 
                   dismissible={!!this.props.delay} onClose={this.props.onClose} >
                {this.props.message}
            </Alert>
        )
    }
}
