import {fabric} from "fabric";
import {v4 as uuidv4} from "uuid";

// Inspiration for this approach over using a group: 
// https://stackoverflow.com/questions/57633710/resize-fabric-rect-without-resizing-textbox
export const fabricOvalTaskDefinition = {
    type: 'ovalTask',
    text: null,
    avatar: null,
    assignee: null,
    avatarText: null,
    prevObjectStacking: null,
    taskId: null,
    state: null,
    jiraKey: null,

    recalcTextPosition: function () {
        this.text.set('left', this.left)
        this.text.set('top', this.top)
        this.text.set('height', this.height * this.scaleY)
        this.text.set('width', this.width * this.scaleX)

        //make the avatar move with the ellipse / textbox
        this.avatar.set('left', this.left + (this.rx * this.scaleX) - 10)
        this.avatar.set('top', this.top)
        this.avatarText.set('left', this.left + (this.rx * this.scaleX) - 10)
        this.avatarText.set('top', this.top)
    },

    setAvatar: function (user) {
        const oldAssignee = this.assignee;
        if (user === null) {
            //Need to remove the avatar (user has set to None)
            this.avatarText.text = '';
            this.avatar.fill = 'white';

            if (oldAssignee !== null) {
                //remove from canvas if it is currently there
                this.canvas.remove(this.avatar);
                this.canvas.remove(this.avatarText);
            }
        } else {
            // need to update the canves as per params
            const colour = user.colour;
            this.avatarText.text = user.initials;
            this.avatar.fill = colour;

            if (oldAssignee === null) {
                //add to the canvas if not currently present
                this.canvas.add(this.avatar);
                this.canvas.add(this.avatarText);
            }
        }
        this.assignee = user ? user.id : null;
        this.avatarText.dirty = true;
        this.avatar.dirty = true;
    },

    setState: function (state) {
        this.state = state.value;
        this.stroke = state.colour;
        this.fill = state.fillColour;
        this.text.fill = state.colour;
    },
    initialize: function (ellipseOptions, textOptions, text, state, assignee, taskId, jiraKey) {
        this.callSuper('initialize', {
            rx: 70,
            ry: 30,
            originX: 'center',
            originY: 'center',
            fill: state.fillColour,
            opacity: 0.6,
            stroke: state.colour,
            strokeWidth: 2,
            strokeUniform: true,
            ...ellipseOptions
        })
        this.text = new fabric.Textbox(text, {
            fontSize: 20,
            fontFamily: 'Arial',
            originX: 'center',
            originY: 'center',
            fill: state.colour,
            left: this.left,
            top: this.top,
            textAlign: 'center',
            selectable: false,
            evented: false,
            excludeFromExport: true,
            ...textOptions,
        })
        this.avatar = new fabric.Circle({
            radius: 20,
            fill: assignee ? assignee.colour : 'white',
            originX: 'center',
            originY: 'center',
            stroke: 'black',
            strokeWidth: 1,
            excludeFromExport: true,
            selectable: false,
            evented: false,
        });
        this.avatarText = new fabric.Textbox(assignee ? assignee.initials : '', {
            fontSize: 20,
            originX: 'center',
            originY: 'center',
            selectable: false,
            textAlign: 'center',
            evented: false,
            excludeFromExport: true
        })
        this.assignee = assignee ? assignee.id : null;
        this.prevObjectStacking = ellipseOptions.prevObjectStacking || 0;
        this.recalcTextPosition();
        this.taskId = taskId || uuidv4();
        this.state = state.value;
        this.text.rotate(this.angle);
        this.jiraKey = jiraKey;

        this.on('moving', (e) => {
            //Prevent object moving off canvas
            const top = e.transform.target.top;
            const left = e.transform.target.left;
            if (top < 0) {
                this.top = 0;
            }
            if (left < 0) {
                this.left = 0;
            }
            if (top > this.canvas.height) {
                this.top = this.canvas.height;
            }
            if (left > this.canvas.width) {
                this.left = this.canvas.width;
            }

            this.recalcTextPosition()
        })
        this.on('rotating', () => {
            this.text.rotate(this.angle)
            //todo sort out avatar rotate
            this.recalcTextPosition()
        })
        this.on('scaling', (e) => {
            this.recalcTextPosition()
        })
        this.on('added', () => {
            this.canvas.add(this.text)
            if (this.assignee !== null) {
                //add the avatar & text to the canvas if present
                this.canvas.add(this.avatar);
                this.canvas.add(this.avatarText);
            }
        })
        this.on('removed', () => {
            //always remove all objects on the canvas when parent is removed
            this.canvas.remove(this.text)
            this.canvas.remove(this.avatar)
            this.canvas.remove(this.avatarText)
        })
        this.on('mousedown:before', () => {
            this._prevObjectStacking = this.canvas.preserveObjectStacking
            this.canvas.preserveObjectStacking = true
        })
        this.on('mousedblclick', () => {
            this.text.selectable = true
            this.text.evented = true
            this.canvas.setActiveObject(this.text)
            this.text.enterEditing()
            this.selectable = false
        })
        this.on('deselected', () => {
            this.canvas.preserveObjectStacking = this._prevObjectStacking
        })
        this.text.on('editing:exited', () => {
            this.text.selectable = false
            this.text.evented = false
            this.selectable = true
        })

    },

    toObject: function() {
        return fabric.util.object.extend(this.callSuper('toObject'), {
            text: this.text.toObject(),
            prevObjectStacking: this.prevObjectStacking,
            assignee: this.assignee,
            taskId: this.taskId,
            state: this.state,
        });
    },
}

export const fabricNoteDefinition = {
    type: 'ovNote',

    recalcTextPosition: function () {
        // change the actual width to avoid scaling
        // height is controlled by the TextBox component
        this.set('width', this.width * this.scaleX)
        this.set('scaleY', 1)
        this.set('scaleX', 1)
    },

    initialize: function (text, textOptions) {
        this.callSuper('initialize', text, {
            width: 100,
            originX: 'center',
            originY: 'center',
            backgroundColor: 'Yellow',
            opacity: 0.8,
            fontSize: 15,
            fontFamily: 'Arial',
            ...textOptions
        })

        this.on('scaling', (e) => {
            this.recalcTextPosition()
        })

        this.on('moving', (e) => {
            //Prevent object moving off canvas
            const top = e.transform.target.top;
            const left = e.transform.target.left;
            if (top < 0) {
                this.top = 0;
            }
            if (left < 0) {
                this.left = 0;
            }
            if (top > this.canvas.height) {
                this.top = this.canvas.height;
            }
            if (left > this.canvas.width) {
                this.left = this.canvas.width;
            }
        })
    },

}

export const transformFabricObjectsToTasks = (objects) => (
    objects.filter(obj => obj.type === 'ovalTask').map((obj) => (
        {
            id: obj.taskId,
            text: obj.text.text,
            textSize: obj.text.fontSize,
            status: obj.state,
            assigneeId: obj.assignee,
            canvasProperties: {
                left: obj.left,
                top: obj.top,
                width: obj.width,
                height: obj.height,
                rx: obj.rx,
                ry: obj.ry,
                scaleX: obj.scaleX,
                scaleY: obj.scaleY,
                angle: obj.angle
            }
        }))
)

export const transformFabricObjectsToNotes = (objects) => (
    objects.filter(obj => obj.type === 'ovNote').map((obj) => (
        {
            text: obj.text,
            textSize: obj.fontSize,
            left: obj.left,
            top: obj.top,
            width: obj.width,
            height: obj.height,
            angle: obj.angle
        }))
)

fabric.OvalTask = fabric.util.createClass(fabric.Ellipse, fabricOvalTaskDefinition)
fabric.OvNote = fabric.util.createClass(fabric.Textbox, fabricNoteDefinition)
