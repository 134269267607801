import React, {Component} from 'react';
import {Helmet} from "react-helmet";

class HowTo extends Component {

        render() {

            const headerStyle = {
                backgroundColor: "#D8FFF7",
                backgroundImage: "linear-gradient(to bottom right,#eae6ff, #fff)",
            };

            return (

                <div>
                    <Helmet>
                        <title>Ovalview | Help</title>
                    </Helmet>
                    <div className="jumbotron" style={headerStyle}>
                        <h1 className="display-4 text-center">Ovalview - Help</h1>
                        <p className="lead text-center">Some pointers toward unlocking the productivity features
                        </p>
                        <br/>
                        <p className="lead" align="center">
                            <button className="btn btn-primary btn-lg" onClick={this.props.history.goBack}>Got it</button>
                        </p>

                        <br/>

                        <div className="row justify-content-around">
                            <div className="col" align="center"/>
                            <div className="col" align="left">
                                <br/>
                                <p className="display-4 text-center">Organisations</p>
                                <p className="display-5 text-center">It's all about the Org!</p>
                                <p className="display-5 text-left">Creating an Org unlocks User Collaboration, Folders, and Jira Integration</p>
                                <p className="display-5 text-left">Click on the Org menu tab to get started.  Name your Org and invite existing Ovalview users</p>
                            </div>
                            <div className="col" align="center"/>
                            {/*<div className="col" align="center">*/}
                            {/*    <img src="siteimages/noimage.png" className="d-block w25" alt="Org"/>*/}
                            {/*    <br/>*/}
                            {/*</div>*/}
                        </div>
                        <br/>
                        <div className="row justify-content-around">
                            <div className="col-1" align="left"/>

                            <div className="col">
                                    <p className="display-4 text-center">Jira Integration</p>
                                    <p className="display-5 text-left">Create a user in jira or select an existing user</p>
                                    <p className="display-5 text-left">create an API key for that user</p>
                                    <p className="display-5 text-left">Assign that user a cool avatar, like this one...<img src="ovalview-icon.png" alt="cool icon" width="30" height="30"/></p>
                                    <p className="display-5 text-left">Enable by configuring jira in the Org panel</p>

                            </div>
                            <div className="col" align="center">
                                <p/>
                                <br/>
                                <img src="siteimages/jira-tickets.png" className="d-block w-25" alt="Jira Tickets"/>
                            </div>
                        </div>
                        <br/>

                        <div className="row justify-content-around">
                            <div className="col" align="center">
                                <img src="siteimages/docId.png" className="d-block w-50" alt="Select Background"/>
                                <br/>
                            </div>
                            <div className="col" align="center">
                                <p/>
                                <p/>
                                <p className="display-4 text-center">Folders</p>
                                <p className="display-5 text-center"></p>
                                <p className="display-5 text-center">Organize and group your projects by using Folders</p>
                                <p className="display-5 text-center">Pages in Folders are accessible by all members of your organisation</p>
                                <p className="display-5 text-center">Pages in the Private folder are only accessible by you</p>
                                <p className="display-5 text-center">Folders can be created from within the Org menu</p>
                            </div>
                        </div>

                        <br/>
                        <div className="row justify-content-around">

                            <div className="col">
                                <p className="display-4 text-center">User Collaboration</p>
`                               <p className="display-5 text-center">Share pages with your team</p>
                                <p className="display-5 text-center">Invite existing Ovalview users to your Org</p>
                            </div>
                            <div className="col" align="center">
                                <img src="siteimages/addusers.png" className="d-block w-25" alt="user-collab"/>
                            </div>
                        </div>

                        <p className="lead" align="center">
                            <button className="btn btn-primary btn-lg" onClick={this.props.history.goBack}>Got it</button>
                        </p>

                    </div>
                </div>
            );
        }
}

export default HowTo
